import React, { useEffect, useState } from 'react';
import api from '../../utils/api';

const Index = ({ authUser }) => {
  const { batchId = 2, batch } = authUser?.currentlyProgram.batch || {};
  const [timeline, setTimeline] = useState([]);
  const [loadingKeys, setLoadingKeys] = useState([]);
  const [statusAcceptance, setStatusAcceptance] = useState([]);
  const [statusAcceptanceProgress, setStatusAcceptanceProgress] = useState(0);

  useEffect(() => {
    const getTimelineByBatchId = async () => {
      const data = await api.getTimelineByBatchId({ batchId });
      setTimeline(data);
    }

    const getStatusAcceptance = async () => {
      const data = await api.getStatusAcceptance();

      const total = data.length; // Total statuses
      const checkedCount = data.filter((item) => item.isChecked === 1).length;
      const progress = (checkedCount / total) * 100; // Calculate progress %

      setStatusAcceptance(data);
      setStatusAcceptanceProgress(progress);
    }

    getTimelineByBatchId();
    getStatusAcceptance();
  }, []);

  const lastChecked = [...statusAcceptance]
    .reverse()
    .find((value) => value.isChecked);



  return (
    <div className='row g-3'>

      <div className="col-12 col-md-6">
        <div className='card'>
          <div className="card-body">
            <h1 className='mb-3 h5'>Timeline Magang CLP {batch}</h1>

            <div class="list-group">

              {timeline.map((value, key) => (
                <label class={`list-group-item d-flex gap-3 py-3 ${!value.activeStatus.status && 'bg-body-tertiary'}`} key={key}>
                  <div className="d-flex gap-2 w-100 justify-content-between">
                    <span class="pt-1 form-checked-content">
                      <strong>{value.title}</strong>
                      <small class="d-block text-body-secondary">
                        {value.startDate} - {value.endDate}
                      </small>
                    </span>
                    <div className="d-flex align-items-center">
                      <span class={`badge rounded-pill ${value.activeStatus.status ? 'text-bg-primary' : 'text-bg-success'}`}>
                        {value.activeStatus.message}
                      </span>
                    </div>
                  </div>
                </label>
              ))}

            </div>

          </div>
        </div>
      </div>

      <div className="col-12 col-md-6">
        <div className="card">
          <div className="card-body">
            <h1 className="mb-3 h5">Status Penerimaan</h1>

            <div class="position-relative m-4">
              <div class="progress" role="progressbar" aria-label="Progress" aria-valuenow="50" aria-valuemin="0" aria-valuemax="100" style={{ height: '1px' }}>
                <div class="progress-bar" style={{ width: `${statusAcceptanceProgress}%` }}></div>
              </div>

              <div class="d-flex justify-content-between">

                {statusAcceptance?.map((value, key) => {
                  return (
                    <button key={key} type="button"
                      className={`translate-middle btn btn-sm ${value.isChecked
                        ? value.statusAcceptanceProgramId === 4 || value.statusAcceptanceProgramId === 6
                          ? "btn-success"
                          : "btn-primary"
                        : "btn-secondary"
                        } rounded-pill`}
                      style={{ width: '2rem', height: '2rem' }}>
                      {value?.statusAcceptanceProgramId}
                    </button>
                  )
                })}
              </div>
              {/* <button type="button" class="position-absolute top-0 start-60 translate-middle btn btn-sm btn-primary rounded-pill" style={{ width: '2rem', height: '2rem' }}>4</button>
              <button type="button" class="position-absolute top-0 start-80 translate-middle btn btn-sm btn-primary rounded-pill" style={{ width: '2rem', height: '2rem' }}>5</button>
              <button type="button" class="position-absolute top-0 start-100 translate-middle btn btn-sm btn-secondary rounded-pill" style={{ width: '2rem', height: '2rem' }}>6</button> */}

              {lastChecked && (
                <>
                  <div className={`alert ${lastChecked.isChecked
                    ? lastChecked.statusAcceptanceProgramId === 4 || lastChecked.statusAcceptanceProgramId === 6
                      ? "alert-success"
                      : "alert-primary"
                    : "alert-secondary"
                    }`} role="alert">
                    {lastChecked.note}
                  </div>
                  <span>
                    <small class="d-block text-body-secondary">
                      Last Updated: {' '}
                      <strong>
                        {lastChecked.lastUpdated}
                      </strong>
                    </small>
                    {lastChecked.updatedBy.firstName !== "" && lastChecked.updatedBy.lastName !== "" &&
                      <small class="d-block text-body-secondary">
                        Updated By: {' '}
                        <strong>
                          {`${lastChecked.updatedBy.firstName} ${lastChecked.updatedBy.lastName}`}
                        </strong>
                      </small>
                    }
                  </span>
                </>
              )}
            </div>

            {/* <div class="list-group"> */}

            {/* {statusAcceptance?.map((value, key) => (
                <label class="list-group-item d-flex gap-2" key={key}>

                  {loadingKeys.includes(key) ? (
                    <div class="spinner-border spinner-border-sm" role="status">
                      <span class="visually-hidden">Loading...</span>
                    </div>
                  ) : (
                    <input
                      disabled={true}
                      class="form-check-input flex-shrink-0" type="checkbox"
                      value={value.statusAcceptanceProgramId}
                      checked={value.isChecked}
                    />
                  )}

                  <span>
                    {value.name}
                    <small class="d-block text-body-secondary">
                      Last Updated: {' '}
                      <strong>
                        {value.lastUpdated}
                      </strong>
                    </small>
                    {value.updatedBy.firstName !== "" && value.updatedBy.lastName !== "" &&
                      <small class="d-block text-body-secondary">
                        Updated By: {' '}
                        <strong>
                          {`${value.updatedBy.firstName} ${value.updatedBy.lastName}`}
                        </strong>
                      </small>
                    }
                  </span>
                </label>
              ))} */}

            {/* </div> */}

          </div>
        </div>
      </div>
    </div >
  )
}

export default Index