import React, { useState, useEffect } from 'react';
import api from '../../../../utils/api';
import { useNavigate, useParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import Editor from 'react-simple-wysiwyg';

const DivisionsAdd = () => {
  const navigate = useNavigate();
  const { id } = useParams();

  const { control, register, handleSubmit, watch, setValue, formState: { errors, touchedFields } } = useForm({
    defaultValues: {
      batchId: 3, // CLP Batch 10
    }
  });

  const criteriaHardskill = watch("requirements.criteriaHardskill", "");
  const criteriaSoftskill = watch("requirements.criteriaSoftskill", "");
  const criteriaOthers = watch("requirements.criteriaOthers", "");

  const [divisions, setDivisions] = useState([]);
  const [isLoading, setIsloading] = useState(false);

  useEffect(() => {
    const onHandeGetDivisions = async () => {
      const data = await api.adminGetDivisions();
      setDivisions(data);
    }

    onHandeGetDivisions();
  }, []);

  const onHandleSubmit = async (data) => {
    setIsloading(true);
    const { status, message } = await api.adminAddDivisionByInstitutions({ id, payload: data });
    if (status !== 'success') {
      alert(message);
    } else {
      alert(message);
      navigate(`/admin/institutions/${id}`)
    }
    setIsloading(false);
  }

  return (
    <form onSubmit={handleSubmit(onHandleSubmit)}>
      <div className="card">
        <div className="card-body">
          <h1 className='h5'>Tambah Divisi</h1>
          <div className="row g-3">

            <div className="col-8">
              <label htmlFor="country" className="form-label">Divisi</label>
              <select
                disabled={!divisions?.length > 0}
                {...register("divisionsId", { required: 'Divisi wajib diisi!' })}
                className="form-select"
              >
                <option value="">Pilih Divisi</option>
                {divisions?.map((value, key) => (
                  <option key={key} value={value.divisionId}>
                    {value.divisionName}
                  </option>
                ))}
              </select>
            </div>

            <div className="col-4">
              <label htmlFor="country" className="form-label">Batch</label>
              <select
                {...register("batchId", { required: 'Batch wajib diisi!' })}
                className="form-select"
              >
                <option value="">Pilih Batch</option>
                <option value={1}>8</option>
                <option value={2}>9</option>
                <option value={3}>10</option>
              </select>
            </div>

            <hr />

            <h6>Persyaratan</h6>

            <div className="col-12 col-md-5">
              <label className="form-label">Jurusan</label>
              <input {...register("requirements.major", { required: 'Divisi wajib diisi!' })} type="text" className="form-control" placeholder='Sistem Informasi, Teknik Informatika' />
            </div>

            <div className="col-6 col-md-4">
              <label className="form-label">Jenjang</label>
              <input {...register("requirements.educationalLevel", { required: 'Jenjang wajib diisi!' })} type="text" className="form-control" />
            </div>

            <div className="col-6 col-md-3">
              <label className="form-label">Minimal Semester</label>
              <input {...register("requirements.minSemester", { required: 'Minimal Semester wajib diisi!' })} type="number" className="form-control" placeholder='5' />
            </div>

            <div className="col-12">
              <label className="form-label">Kriteria Hard Skills</label>
              <Editor value={criteriaHardskill} onChange={(e) => setValue("requirements.criteriaHardskill", e.target.value, { shouldValidate: true })} />
            </div>

            <div className="col-12">
              <label className="form-label">Kriteria Soft Skills</label>
              <Editor value={criteriaSoftskill} onChange={(e) => setValue("requirements.criteriaSoftskill", e.target.value, { shouldValidate: true })} />
            </div>

            <div className="col-12">
              <label className="form-label">Kriteria lainnya</label>
              <Editor value={criteriaOthers} onChange={(e) => setValue("requirements.criteriaOthers", e.target.value, { shouldValidate: true })} />
            </div>

            <div className="col-12">
              <label className="form-label">WhatsApp Group</label>
              <input {...register("requirements.whatsAppGroup")} type="text" className="form-control" placeholder='https://chat.whatsapp.com/xxx' />
            </div>

            <div className="col-12">
              <div className="d-grid gap-2">
                <button type="submit" disabled={divisions?.length <= 0 || isLoading} className='btn btn-primary' >
                  {isLoading ?
                    <>
                      <span class="spinner-border spinner-border-sm" aria-hidden="true"></span>
                      <span role="status"> Loading...</span>
                    </>
                    :
                    <>
                      <span>Simpan</span>
                    </>
                  }
                </button>
              </div>
            </div>

          </div>
        </div>
      </div>
    </form>
  )
}

export default DivisionsAdd;
