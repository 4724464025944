import React, { useState, useEffect } from 'react'
import { Routes, Route, Outlet, useNavigate, Navigate } from 'react-router-dom';
import { logoutAction } from './utils/action';

// Api
import api from './utils/api';

// Components
import Navigation from './Components/Navigation';
import Footer from './Components/Footer';
import Sidebar from './Components/Sidebar';

// Pages

// Update
import UpdateIndex from './Pages/Update/Index';
// import UpdateDiri from './Pages/Update/UpdateDiri';
// import MinatBakatDiri from './Pages/Update/MinatBakatDiri';
// import MediaSosial from './Pages/Update/MediaSosial';
// import Mobilisasi from './Pages/Update/Mobilisasi';
import Profile from './Pages/Update/Profile';

// Publication
import PublicationList from './Pages/Publication/PublicationList';
import PublicationDetail from './Pages/Publication/PublicationDetail';

// Program
import ProgramIndex from './Pages/Program/ProgramIndex';

// Registrations
import RegistrationsIndex from './Pages/Program/Registrations/Index';
import RegistrationsInstitutions from './Pages/Program/Registrations/Institutions';
import RegistrationsOthers from './Pages/Program/Registrations/Others';


// App
import AppIndex from './Pages/App/Index';
import Login from './Pages/App/Login';
import ProfileApp from './Pages/App/Profile/Index';
import PublicationAppIndex from './Pages/App/Publication/Index';
import PublicationAppAdd from './Pages/App/Publication/PublicationAdd';
import PublicationAppDetail from './Pages/App/Publication/PublicationDetail';
import PublicationAppEdit from './Pages/App/Publication/PublicationEdit';
import UpdateDiriApp from './Pages/App/Profile/UpdateProfile/UpdateDiri';
import UpdateMobilisasiApp from './Pages/App/Profile/UpdateProfile/Mobilisasi';
import UpdateMinatBakatDiriApp from './Pages/App/Profile/UpdateProfile/MinatBakatDiri';
import UpdateMediaSosialApp from './Pages/App/Profile/UpdateProfile/MediaSosial';
import AppEvaluationList from './Pages/App/Evaluation/EvaluationList';
import AppEvaluationDetail from './Pages/App/Evaluation/EvaluationDetail';
import AppEvaluationAdd from './Pages/App/Evaluation/EvaluationAdd';
import AppEvaluationFinal from './Pages/App/Evaluation/EvaluationFinal';

// Admin
import AdminIndex from './Pages/Admin/Index';
import AdminLogin from './Pages/Admin/Login';
import AdminPublicationDetail from './Pages/Admin/Publication/PublicationDetail';
import AdminPublicationList from './Pages/Admin/Publication/PublicationList';
import AdminEvaluationList from './Pages/Admin/Evaluation/EvaluationList';
import AdminEvaluationDetail from './Pages/Admin/Evaluation/EvaluationDetail';
import AdminPATEvaluationDetail from './Pages/Admin/EvaluationPAT/DetaiEvaluated';
import AdminPATHasEvaluationDetail from './Pages/Admin/EvaluationPAT/DetailHasEvaluate';
import AdminEvaluationAdd from './Pages/Admin/Evaluation/EvaluationAdd';
import AdminUsersList from './Pages/Admin/Users/UsersList';
import AdminUsersDetail from './Pages/Admin/Users/UsersDetail';
import MentorsList from './Pages/Admin/Mentors/MentorsList';
import MentorsDetail from './Pages/Admin/Mentors/MentorsDetail';
import MentorsAdd from './Pages/Admin/Mentors/MentorsAdd';
import ClassificationsHardskills from './Pages/Admin/Classifications/ClassificationsHardskills';
import ClassificationsAdd from './Pages/Admin/Classifications/ClassificationsAdd';
import ClassificationsUpdate from './Pages/Admin/Classifications/ClassificationsUpdate';
import AdminInstitutionsList from './Pages/Admin/Institutions/InstitutionsList';
import AdminInstitutionsDetail from './Pages/Admin/Institutions/Detail';
import AdminInstitutionsDivisionsAdd from './Pages/Admin/Institutions/Divisions/DivisionsAdd';
import AdminInstitutionsDivisionsDetail from './Pages/Admin/Institutions/Divisions/DivisionsDetail';
import AdminUniversitiesList from './Pages/Admin/Universities/UniversitiesList';

// Mentors
import MentorsIndex from './Pages/Mentors/Index';
import MentorsLogin from './Pages/Mentors/Login';
import MentorsEvaluationList from './Pages/Mentors/Evaluation/EvaluationList';
import MentorsEvaluationDetail from './Pages/Mentors/Evaluation/EvaluationDetail';
import EvaluationHSAdd from './Pages/Mentors/Evaluation/EvaluationHSAdd';
import MentorsClassificationsHardskills from './Pages/Mentors/Classifications/ClassificationsHardskills';
import MentorsClassificationsAdd from './Pages/Mentors/Classifications/ClassificationsAdd';
import MentorsPATEvaluationDetail from './Pages/Mentors/EvaluationPAT/DetaiEvaluated';
import EvaluationSSAdd from './Pages/Mentors/Evaluation/EvaluationSSAdd';
import RegistrationsList from './Pages/Mentors/Registrations/RegistrationsList';
import RegistrationsDetail from './Pages/Mentors/Registrations/RegistrationsDetail';
import Register from './Pages/Register';
import Home from './Pages/Home';
import ForgetPassword from './Pages/ForgetPassword';
import UniversitiesDetail from './Pages/Admin/Universities/UniversitiesDetail';
import UniversitiesUpdate from './Pages/Admin/Universities/UniversitiesUpdate';

function App() {
  const navigate = useNavigate();
  const [authUser, setAuthUser] = useState(null);
  const [refreshPreloadProcess, setRefreshPreloadProcess] = useState(false);

  useEffect(() => {
    const asyncPreloadProcess = async () => {
      const { data: authUsers } = await api.getOwnProfile();
      const offeredProgram = await api.getOwnOfferedProgram();
      const data = {
        ...authUsers,
        offeredProgram,
      }
      setRefreshPreloadProcess(false);
      switch (data?.currentlyProgram?.role?.roleName) {
        case 'Mahasiswa':
          navigate('/app')
          break;
        case 'Mentor':
          navigate('/mentor')
          break;
        case 'Admin':
          navigate('/admin')
          break;
        default:
          break;
      }
      setAuthUser(data);
    }

    asyncPreloadProcess();
  }, [refreshPreloadProcess]);

  const onSignOut = () => {
    navigate('/')
    setAuthUser(null);
    logoutAction();
  }

  const ProtectedRoute = ({ authUser, children }) => {
    if (!authUser || authUser?.currentlyProgram?.role?.roleName !== 'Mahasiswa') {
      return <Navigate to="/app/login" replace />
    }
    return children ? children : <Outlet />
  }

  const ProtectedRouteRegistrations = ({ authUser, children }) => {
    if (!authUser) {
      return <Navigate to="/app/login" replace />
    }
    return children ? children : <Outlet />
  }

  const ProtectedRouteAdmin = ({ authUser, children }) => {
    if (!authUser || authUser?.currentlyProgram?.role?.roleName !== 'Admin') {
      return <Navigate to="/admin/login" replace />
    }
    return children ? children : <Outlet />
  }

  const ProtectedRouteMentors = ({ authUser, children }) => {
    if (!authUser || authUser?.currentlyProgram?.role?.roleName !== 'Mentor') {
      return <Navigate to="/mentors/login" replace />
    }
    return children ? children : <Outlet />
  }

  const LoggedInRoute = ({ authUser, children }) => {
    if (authUser && authUser?.role === 'Mahasiswa') {
      return <Navigate to="/app" replace />
    }
    return children ? children : <Outlet />
  }

  const LoggedInRouteAdmin = ({ authUser, children }) => {
    if (authUser && authUser?.role === 'Admin') {
      return <Navigate to="/admin" replace />
    }
    return children ? children : <Outlet />
  }

  const LoggedInRouteMentors = ({ authUser, children }) => {
    if (authUser && authUser?.role === 'Mentor') {
      return <Navigate to="/mentors" replace />
    }
    return children ? children : <Outlet />
  }

  const AppLayout = () => (
    <div className='container mt-5'>
      <Outlet />
    </div>
  )

  const AdminLayout = ({ authUser, signOut }) => (
    <div className='container'>
      <div className='row pt-5'>
        <div className="col-3">
          <div className="sticky-md-top" style={{ top: 20 }}>
            <Sidebar authUser={authUser} signOut={signOut} />
          </div>
        </div>
        <div className="col-9">
          <Outlet />
        </div>
      </div>
    </div>
  )

  const MentorsLayout = ({ authUser, signOut }) => (
    <div className='container'>
      <div className='row pt-5'>
        <div className="col-3">
          <div className="sticky-md-top" style={{ top: 20 }}>
            <Sidebar authUser={authUser} signOut={signOut} />
          </div>
        </div>
        <div className="col-9">
          <Outlet />
        </div>
      </div>
    </div>
  )

  return (
    <>
      <header>
        <Navigation authUser={authUser} setAuthUser={setAuthUser} signOut={onSignOut} />
      </header>
      <main>
        <Routes>
          <Route path='/' element={<AppLayout />}>
            {/* <Route index element={<Login setAuthUser={setAuthUser} />} /> */}
            <Route index element={<Home />} />
            {/* <Route path='update'>
              <Route index element={<UpdateIndex />} />
              <Route path='profile' element={<Profile />} />
              <Route path='diri' element={<UpdateDiri />} />
              <Route path='mobilisasi' element={<Mobilisasi />} />
              <Route path='minat' element={<MinatBakatDiri />} />
              <Route path='sosmed' element={<MediaSosial />} />
            </Route> */}

            <Route path='publication'>
              <Route index element={<PublicationList />} />
              <Route path=':slug' element={<PublicationDetail />} />
            </Route>

            <Route path='program'>
              <Route index element={<ProgramIndex />} />
              <Route element={<ProtectedRouteRegistrations authUser={authUser} />}>
                <Route path='registrations'>
                  <Route index element={<RegistrationsIndex authUser={authUser} />} />
                  <Route path='institutions' element={<RegistrationsInstitutions />} />
                  <Route path='others' element={<RegistrationsOthers setRefreshPreloadProcess={setRefreshPreloadProcess} />} />
                </Route>
              </Route>
            </Route>

            <Route path='register' element={<Register />} />
            <Route path='forget-password' element={<ForgetPassword />} />

            <Route path='app'>
              <Route element={<LoggedInRoute authUser={authUser} />}>
                <Route path='login' element={<Login setAuthUser={setAuthUser} />} />
              </Route>
              <Route element={<ProtectedRoute authUser={authUser} />}>
                <Route index element={<AppIndex authUser={authUser} />} />
                <Route path='profile'>
                  <Route index element={<ProfileApp authUser={authUser} />} />
                  <Route path='update'>
                    <Route path='identitas' element={<UpdateDiriApp authUser={authUser} />} />
                    <Route path='mobilisasi' element={<UpdateMobilisasiApp />} />
                    <Route path='interest-talent' element={<UpdateMinatBakatDiriApp />} />
                    <Route path='social-media' element={<UpdateMediaSosialApp />} />
                  </Route>
                </Route>
                <Route path='publication'>
                  <Route index element={<PublicationAppIndex />} />
                  <Route path='add' element={<PublicationAppAdd />} />
                  <Route path=':id/edit' element={<PublicationAppEdit />} />
                  <Route path=':id' element={<PublicationAppDetail />} />
                </Route>
                <Route path='evaluation'>
                  <Route index element={<AppEvaluationList authUser={authUser} />} />
                  <Route path=':id' element={<AppEvaluationDetail />} />
                  <Route path=':id/evaluate' element={<AppEvaluationAdd authUser={authUser} />} />
                  <Route path='final' element={<AppEvaluationFinal authUser={authUser} />} />
                </Route>
              </Route>
            </Route>
          </Route>

          <Route path='admin'>
            <Route element={<LoggedInRouteAdmin authUser={authUser} />}>
              <Route path='login' element={<AdminLogin setAuthUser={setAuthUser} />} />
            </Route>
            <Route element={<ProtectedRouteAdmin authUser={authUser} />} >
              <Route element={<AdminLayout authUser={authUser} signOut={onSignOut} />}>
                <Route index element={<AdminIndex />} />
                <Route path='publication'>
                  <Route index element={<AdminPublicationList />} />
                  <Route path=':id' element={<AdminPublicationDetail />} />
                </Route>
                <Route path='registrations'>
                  <Route index element={<RegistrationsList authUser={authUser} />} />
                  <Route path=':id'>
                    <Route index element={<RegistrationsDetail authUser={authUser} />} />
                  </Route>
                </Route>
                <Route path='evaluation'>
                  <Route index element={<AdminEvaluationList />} />
                  <Route path=':id'>
                    <Route index element={<AdminEvaluationDetail />} />
                    <Route path='pat/evaluated' element={<AdminPATHasEvaluationDetail />} />
                    <Route path='pat' element={<AdminPATEvaluationDetail />} />
                  </Route>
                  <Route path=':id/evaluate' element={<AdminEvaluationAdd />} />
                </Route>
                <Route path='classifications'>
                  <Route index element={<ClassificationsHardskills />} />
                  <Route path=':divisionInstitutionsId/add' element={<ClassificationsAdd />} />
                  <Route path=':divisionInstitutionsId/update' element={<ClassificationsUpdate />} />
                </Route>
                <Route path='users'>
                  <Route index element={<AdminUsersList authUser={authUser} />} />
                  <Route path=':id' element={<AdminUsersDetail />} />
                </Route>
                <Route path='mentors'>
                  <Route index element={<MentorsList />} />
                  <Route path='add' element={<MentorsAdd />} />
                  <Route path=':id' element={<MentorsDetail />} />
                </Route>
                <Route path='institutions'>
                  <Route index element={<AdminInstitutionsList />} />
                  <Route path=':id' element={<AdminInstitutionsDetail />} />
                  <Route path=':id/add' element={<AdminInstitutionsDivisionsAdd />} />
                  <Route path=':institutionsId/division/:divisionInstitutionsId' element={<AdminInstitutionsDivisionsDetail />} />
                </Route>
                <Route path='universities'>
                  <Route index element={<AdminUniversitiesList />} />
                  <Route path=':id' element={<UniversitiesDetail />} />
                  <Route path=':id/update' element={<UniversitiesUpdate />} />
                </Route>
              </Route>
            </Route>
          </Route>

          <Route path='mentor'>
            <Route element={<LoggedInRouteMentors authUser={authUser} />}>
              <Route path='login' element={<MentorsLogin setAuthUser={setAuthUser} />} />
            </Route>
            <Route element={<ProtectedRouteMentors authUser={authUser} />} >
              <Route element={<MentorsLayout authUser={authUser} signOut={onSignOut} />}>
                <Route index element={<MentorsIndex />} />
                <Route path='registrations'>
                  <Route index element={<RegistrationsList authUser={authUser} />} />
                  <Route path=':id'>
                    <Route index element={<RegistrationsDetail />} />
                  </Route>
                </Route>
                <Route path='evaluation'>
                  <Route index element={<MentorsEvaluationList authUser={authUser} />} />
                  <Route path=':id'>
                    <Route index element={<MentorsEvaluationDetail authUser={authUser} />} />
                    <Route path='evaluate/hard-skill' element={<EvaluationHSAdd />} />
                    <Route path='evaluate/soft-skill' element={<EvaluationSSAdd />} />
                    <Route path='pat' element={<MentorsPATEvaluationDetail />} />
                  </Route>
                  <Route path='students' element={<AdminUsersList authUser={authUser} />} />
                </Route>
                <Route path='classifications'>
                  <Route index element={<MentorsClassificationsHardskills authUser={authUser} />} />
                  <Route path='add' element={<MentorsClassificationsAdd authUser={authUser} />} />
                </Route>
              </Route>
            </Route>
          </Route>

          <Route path='*' element={
            <h1 className='h5 text-center m-5'>Halaman tidak ditemukan</h1>
          } />
        </Routes>
      </main>
      <footer>
        <Footer />
      </footer>
    </>
  );
}

export default App;
