import React, { useState, useEffect } from 'react';
import api from '../../../../utils/api';
import { useNavigate, useParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import Editor from 'react-simple-wysiwyg';

const DivisionsDetail = () => {
  const navigate = useNavigate();
  const { institutionsId, divisionInstitutionsId } = useParams();

  const { register, handleSubmit, watch, setValue } = useForm();

  const criteriaHardskill = watch("criteriaHardskill", "");
  const criteriaSoftskill = watch("criteriaSoftskill", "");
  const criteriaOthers = watch("criteriaOthers", "");

  const [divisions, setDivisions] = useState([]);
  const [isLoading, setIsloading] = useState(false);

  useEffect(() => {
    const onHandeGetDivisions = async () => {
      const { data } = await api.adminGetDivisionByInstitutions({ institutionsId, divisionInstitutionsId });
      setDivisions(data);

      if (data?.division?.requirementDivision) {
        Object.keys(data?.division?.requirementDivision).forEach((key) => {
          setValue(key, data?.division?.requirementDivision[key]);
        });
      }
    }

    onHandeGetDivisions();
  }, [divisionInstitutionsId, institutionsId, setValue]);

  const onHandleSubmit = async (data) => {
    setIsloading(true);
    const { status, message } = await api.adminUpdateDivisionByInstitutions({
      institutionsId,
      divisionInstitutionsId,
      payload: data,
    });
    if (status !== 'success') {
      alert(message);
    } else {
      alert(message);
      navigate(`/admin/institutions/${institutionsId}`)
    }
    setIsloading(false);
  }

  return (
    <form onSubmit={handleSubmit(onHandleSubmit)}>
      <div className="card">
        <div className="card-body">
          <h1 className='h5'>Update Divisi</h1>
          <div className="row g-3">

            <div className="col-6">
              <label className="form-label">Lembaga</label>
              <input disabled value={divisions?.institutionsName} type="text" className="form-control" placeholder='Sistem Informasi, Teknik Informatika' />
            </div>

            <div className="col-6">
              <label className="form-label">Divisi</label>
              <input disabled value={divisions?.division?.divisionName} type="text" className="form-control" placeholder='Sistem Informasi, Teknik Informatika' />
            </div>

            <hr />

            <div className="col-12 col-md-5">
              <label className="form-label">Jurusan</label>
              <input {...register("major", { required: 'Divisi wajib diisi!' })} type="text" className="form-control" placeholder='Sistem Informasi, Teknik Informatika' />
            </div>

            <div className="col-6 col-md-4">
              <label className="form-label">Jenjang</label>
              <input {...register("educationalLevel", { required: 'Jenjang wajib diisi!' })} type="text" className="form-control" />
            </div>

            <div className="col-6 col-md-3">
              <label className="form-label">Minimal Semester</label>
              <input {...register("minSemester", { required: 'Minimal Semester wajib diisi!' })} type="number" className="form-control" placeholder='5' />
            </div>

            <div className="col-12">
              <label className="form-label">Kriteria Hard Skills</label>
              <Editor value={criteriaHardskill} onChange={(e) => setValue("criteriaHardskill", e.target.value, { shouldValidate: true })} />
            </div>

            <div className="col-12">
              <label className="form-label">Kriteria Soft Skills</label>
              <Editor value={criteriaSoftskill} onChange={(e) => setValue("criteriaSoftskill", e.target.value, { shouldValidate: true })} />
            </div>

            <div className="col-12">
              <label className="form-label">Kriteria lainnya</label>
              <Editor value={criteriaOthers} onChange={(e) => setValue("criteriaOthers", e.target.value, { shouldValidate: true })} />
            </div>

            <div className="col-12">
              <label className="form-label">WhatsApp Group</label>
              <input {...register("whatsAppGroup")} type="text" className="form-control" placeholder='https://chat.whatsapp.com/xxx' />
            </div>

            <div className="col-12">
              <div className="d-grid gap-2">
                <button type="submit" disabled={divisions?.length <= 0 || isLoading} className='btn btn-primary' >
                  {isLoading ?
                    <>
                      <span class="spinner-border spinner-border-sm" aria-hidden="true"></span>
                      <span role="status"> Loading...</span>
                    </>
                    :
                    <>
                      <span>Ubah</span>
                    </>
                  }
                </button>
              </div>
            </div>

          </div>
        </div>
      </div>
    </form>
  )
}

export default DivisionsDetail;
