import React, { useState, useEffect } from 'react';
import api from '../../../utils/api';
import { useNavigate, useSearchParams } from 'react-router-dom';

const EvaluationList = ({ authUser }) => {
  const offeredProgram = authUser?.offeredProgram || [];
  const navigate = useNavigate();

  const [searchParams, setSearchParams] = useSearchParams();

  const [loading, setLoading] = useState(true);
  const [evaluates, setEvaluates] = useState([]);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(25);
  const [batchId, setBatchId] = useState(searchParams.get("batch") || "1");


  useEffect(() => {
    const getAllEvaluates = async () => {
      setLoading(true);
      const data = await api.adminGetAllEvaluates({ batchId, page, pageSize, filterBy: 'mentor' });
      setEvaluates(data);
      setLoading(false);
    }

    getAllEvaluates();
  }, [pageSize, page, batchId]);

  const handleBatchId = (batchId) => {
    setSearchParams({ ...searchParams, batch: batchId });
    navigate(`?batch=${batchId}`)
  }

  return (
    <div className='card'>
      <div className="card-body table-responsive">
        <div className="d-flex justify-content-between mb-3">
          <h1 className='h5'>Mahasiswa</h1>
          <div className="row">
            <div className="col-4">
              <select onChange={(e) => setPageSize(e.target.value)} class="form-select" aria-label="Default select example">
                <option value={25}>25</option>
                <option value={50}>50</option>
                <option value={100}>100</option>
              </select>
            </div>
            <div className="col-4">
              <select value={batchId} onChange={(e) => handleBatchId(e.target.value)} className="form-select" aria-label="Default select example">
                {offeredProgram?.map((value, key) => (
                  <option key={key} value={value.batchId}>BATCH: {value.batch.batch}</option>
                ))}
              </select>
            </div>
            <div className="col-2">
              <div className="d-grid gap-2">
                <button onClick={() => navigate('/mentor/evaluation/students')} className='btn btn-primary'>Tambah</button>
              </div>
            </div>
          </div>
        </div>
        <table className="table table-hover">
          <thead>
            <tr>
              <th scope="col">#</th>
              <th scope="col">Mentors</th>
              <th scope="col">Mentess</th>
              <th scope="col">Jurusan</th>
              <th scope="col">Univesitas</th>
              <th scope="col">Divisi</th>
              <th scope="col">Nilai</th>
              <th scope="col">PAT</th>
            </tr>
          </thead>
          <tbody>
            {loading ?
              <>
                {Array.apply(null, Array(Number(pageSize))).map(() => (
                  <tr className='placeholder-glow'>
                    <th><span className='placeholder col-12'></span></th>
                    <td><span className='placeholder col-12'></span></td>
                    <td><span className='placeholder col-12'></span></td>
                    <td><span className='placeholder col-12'></span></td>
                    <td><span className='placeholder col-12'></span></td>
                    <td><span className='placeholder col-12'></span></td>
                    <td><span className='placeholder col-12'></span></td>
                    <td><span className='placeholder col-12'></span></td>
                    <td><span className='placeholder col-12'></span></td>
                  </tr>
                ))}
              </>
              :
              <>
                {evaluates?.users?.map((value, key) => (
                  <tr key={key} onClick={() => navigate(`/mentor/evaluation/${value.CLPUsersId}`)} style={{ cursor: 'pointer' }}>
                    <th scope="row">{(page - 1) * pageSize + (key + 1)}</th>
                    <td>{`${value.mentors.firstName || ''} ${value.mentors.lastName || ''}`}</td>
                    <td>{`${value.firstName} ${value.lastName}`}</td>
                    <td>{value.universities.universitiesMajor}</td>
                    <td>{value.universities.universitiesName}</td>
                    <td>{value.institutions.divisionName}</td>
                    <td>{value.evaluationFinal.finalEvaluationAverage}</td>
                    <td width={`${15}%`} >
                      <div class="progress" role="progressbar" aria-label="Example with label" aria-valuenow={value.progressPAT.progress} aria-valuemin="0" aria-valuemax="100">
                        <div class={`progress-bar overflow-visible ${value.progressPAT.progress < 10 && 'text-dark'}`} style={{ width: value.progressPAT.progress + '%' }}>{`${value.progressPAT.hasEvaluated}/${value.progressPAT.totalUsersDivision}`}</div>
                      </div>
                    </td>
                  </tr>
                ))}
              </>
            }
          </tbody>
        </table>
        <nav aria-label="Page navigation example">
          <ul class="pagination justify-content-center">
            <li class="page-item">
              <button onClick={() => setPage(evaluates.previous)} class={`page-link ${!evaluates.previous && 'disabled'}`}>Previous</button>
            </li>
            <li class="page-item"><p class="page-link">{evaluates.current + 1}</p></li>
            <li class="page-item">
              <button onClick={() => setPage(evaluates.next + 1)} class={`page-link ${!evaluates.next && 'disabled'}`}>Next</button>
            </li>
          </ul>
        </nav>
      </div>
    </div>
  )
}

export default EvaluationList