import React, { useState, useEffect } from 'react'
import api from '../../../utils/api';
import { useForm, Controller } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';

const Index = ({ authUser }) => {
  const role = authUser?.currentlyProgram?.role?.roleName?.toLowerCase();

  const navigate = useNavigate();
  const { control, register, handleSubmit, setValue, watch, formState: { errors, touchedFields } } = useForm({
    defaultValues: {
      batchId: 3, // CLP Batch 10
      domicilies: [
        {
          statusAddress: "KTP",
          provinciesId: "",
          regenciesId: "",
          completeAddress: "",
        },
        {
          statusAddress: "CURRENT",
          provinciesId: "",
          regenciesId: "",
          completeAddress: "",
        },
      ],
    }
  });
  const [isDisabled, setIsDisabled] = useState(true);
  const [isLoading, setIsLoading] = useState(true);
  const [provincies, setProvincies] = useState([]);
  const [regencies, setRegencies] = useState([]);
  const [universities, setUniversities] = useState([]);
  const [majorUniversities, setMajorUniversities] = useState([]);
  const [searchUniversities, setSearchUniversities] = useState('');

  useEffect(() => {
    const onHandlerGetProvincies = async () => {
      const data = await api.getProvincies();
      setProvincies(data);
    }

    onHandlerGetProvincies();
  }, []);

  useEffect(() => {
    const getOwnProfile = async () => {
      const { data } = await api.getRegistrationsUsers();
      const { firstName, lastName, email, sex, phoneNumber, numberCardId, hasUpdated } = data;
      setValue('fullName', `${firstName} ${lastName}`);
      setValue('email', email);
      setValue('sex', sex);
      setValue('phoneNumber', phoneNumber);
      setValue('numberCardId', numberCardId);
      setIsDisabled(hasUpdated);
      setIsLoading(false)
    }

    getOwnProfile();
  }, []);

  useEffect(() => {
    const onHandlerGetUniversities = async () => {
      const data = await api.adminGetUniversities({ q: searchUniversities, page: 1, pageSize: 300 });
      setUniversities(data.universities);
    }

    onHandlerGetUniversities();
  }, [searchUniversities]);

  const onHandlerGetMajorByUniversities = async ({ id }) => {
    const data = await api.adminGetMajorByUniversities({ id });
    setMajorUniversities(data);
    setValue(`universitiesMajorId`, data.length > 0 ? parseInt(data[0].universitiesMajorId) : null, { shouldValidate: true });
  }

  const domicilies = watch("domicilies");

  const handleProvinceChange = async (provinciesId, key) => {
    const data = await api.getRegenciesByProvincies({ id: provinciesId });
    setRegencies((prev) => ({ ...prev, [key]: data }));
    setValue(`domicilies[${key}].provinciesId`, parseInt(provinciesId), { shouldValidate: true });
    setValue(
      `domicilies[${key}].regenciesId`,
      data.length > 0 ? data[0].regenciesId : "",
      { shouldValidate: true }
    );
  };

  // Handle regencies change
  const handleRegencyChange = (regenciesId, key) => {
    setValue(`domicilies[${key}].regenciesId`, parseInt(regenciesId), { shouldValidate: true });
  };

  const getInputClassName = (fieldName) => {
    if (!touchedFields[fieldName]) return 'form-control'; // Initial render
    return `form-control ${errors[fieldName] ? 'is-invalid' : 'is-valid'}`;
  };

  const getSelectClassName = (fieldName) => {
    if (!touchedFields[fieldName]) return 'form-select'; // Initial render
    return `form-select ${errors[fieldName] ? 'is-invalid' : 'is-valid'}`;
  };

  const onHandleSubmit = async (data) => {
    const regenciesId = data.domicilies.map(d => d.regenciesId);
    navigate('/program/registrations/institutions', { state: { data, regenciesId } });
  }

  if (role === 'mentor' || role === 'admin') {
    return (
      <div class="alert alert-primary text-center" role="alert">
        {role.charAt(0).toUpperCase() + role.slice(1)} tidak bisa mendaftar program Internship!
      </div>
    )
  }

  return (
    <form onSubmit={handleSubmit(onHandleSubmit)} className="needs-validation" noValidate>
      <div className='row g-3 m-auto' style={{ maxWidth: 850, padding: '1rem' }}>

        <div className="card">
          <div className="card-body">
            <h4 className="mb-3">Program</h4>

            <div className="row g-3">

              <div className="col-8">
                <label htmlFor="address" className="form-label">Program</label>
                <input disabled value="Magang Mandiri" type="text" className="form-control" />
              </div>

              <div className="col-4">
                <label htmlFor="address" className="form-label">Batch</label>
                <input disabled value={10} type="number" className="form-control" />
              </div>

            </div>

          </div>
        </div>

        <div className='card'>
          <div className="card-body">
            <h4 className="mb-3">Data Diri</h4>

            <div className="row g-3">

              <div className="col-12">
                <label htmlFor="username" className="form-label">Nama Lengkap</label>
                {isLoading ? (
                  <p class="placeholder-glow">
                    <span class="placeholder col-12 placeholder-lg"></span>
                  </p>
                ) : (
                  <input disabled {...register('fullName')} type="text" className="form-control" />
                )}
              </div>

              <div className="col-12">
                <label htmlFor="email" className="form-label">Email <span className="text-body-secondary"><b><small>Verified</small></b></span></label>
                {isLoading ? (
                  <p class="placeholder-glow">
                    <span class="placeholder col-12 placeholder-lg"></span>
                  </p>
                ) : (
                  <input disabled {...register('email')} type="email" className="form-control" />
                )}
              </div>

              <div className="col-12">
                <label htmlFor="address" className="form-label">NIK *</label>
                {isLoading ? (
                  <p class="placeholder-glow">
                    <span class="placeholder col-12 placeholder-lg"></span>
                  </p>
                ) : (
                  <input
                    disabled={isDisabled.numberCardId}
                    {...register("numberCardId", { required: 'NIK wajib diisi!' })}
                    className={getInputClassName('numberCardId')}
                    type="number"
                  />
                )}
                <div className="invalid-feedback">{errors.numberCardId?.message}</div>
                <div className="valid-feedback">Looks good!</div>
              </div>

              <div className="col-12">
                <label htmlFor="jenis_kelamin" className="form-label">Jenis Kelamin *</label>
                {isLoading ? (
                  <p class="placeholder-glow">
                    <span class="placeholder col-12 placeholder-lg"></span>
                  </p>
                ) : (
                  <select
                    disabled={isDisabled.sex}
                    {...register("sex", { required: 'Jenis Kelamin wajib diisi!' })}
                    className={getSelectClassName('sex')}
                  >
                    <option value="" disabled>Pilih Jenis Kelamin</option>
                    <option value="Laki Laki">Laki Laki</option>
                    <option value="Perempuan">Perempuan</option>
                  </select>
                )}
                <div className="invalid-feedback">{errors.sex?.message}</div>
                <div className="valid-feedback">Looks good!</div>
              </div>

              <div className="col-12">
                <label htmlFor="address" className="form-label">No Telepon *</label>
                {isLoading ? (
                  <p class="placeholder-glow">
                    <span class="placeholder col-12 placeholder-lg"></span>
                  </p>
                ) : (
                  <input
                    disabled={isDisabled.phoneNumber}
                    {...register("phoneNumber", { required: 'No Telepon wajib diisi!' })}
                    className={getInputClassName('phoneNumber')}
                    type="number"
                  />
                )}
              </div>
              <div className="invalid-feedback">{errors.phoneNumber?.message}</div>
              <div className="valid-feedback">Looks good!</div>
            </div>

          </div>
        </div>

        <div className="card">
          <div className="card-body">
            <h4 className="mb-3">Domisili</h4>

            <div className="row g-3">
              {domicilies?.map((value, key) => (
                <React.Fragment key={key}>
                  {/* Province Dropdown */}
                  <div className="col-12 col-md-6">
                    <label className="form-label">
                      Provinsi Domisili{" "}
                      {value.statusAddress === "CURRENT" ? "SAAT INI" : value.statusAddress}
                      {" * "}
                    </label>
                    <Controller
                      name={`domicilies[${key}].provinciesId`}
                      control={control}
                      rules={{
                        required: "Provinsi is required",
                      }}
                      render={({ field }) => (
                        <select
                          {...field}
                          className={`form-select ${errors.domicilies?.[key]?.provinciesId ? "is-invalid" : ""
                            }`}
                          onChange={(e) => {
                            const provinciesId = e.target.value;
                            handleProvinceChange(provinciesId, key);
                          }}
                        >
                          <option value="">Pilih Provinsi</option>
                          {provincies?.map((province, idx) => (
                            <option
                              key={idx}
                              value={province.provinceId}
                            >
                              {province.provinceName}
                            </option>
                          ))}
                        </select>
                      )}
                    />
                    {errors.domicilies?.[key]?.provinciesId && (
                      <div className="invalid-feedback">
                        {errors.domicilies[key].provinciesId.message}
                      </div>
                    )}
                  </div>

                  {/* Regency Dropdown */}
                  <div className="col-12 col-md-6">
                    <label className="form-label">
                      Kota Domisili{" "}
                      {value.statusAddress === "CURRENT" ? "SAAT INI" : value.statusAddress}
                      {" * "}
                    </label>
                    <Controller
                      name={`domicilies[${key}].regenciesId`}
                      control={control}
                      rules={{
                        required: "Kota is required",
                      }}
                      render={({ field }) => (
                        <select
                          {...field}
                          className={`form-select ${errors.domicilies?.[key]?.regenciesId ? "is-invalid" : ""
                            }`}
                          disabled={!regencies[key]?.length > 0}
                          onChange={(e) => {
                            const regenciesId = e.target.value;
                            handleRegencyChange(regenciesId, key);
                          }}
                        >
                          <option value="">Pilih Kota</option>
                          {regencies[key]?.map((regency, idx) => (
                            <option
                              key={idx}
                              value={regency.regenciesId}
                            >
                              {regency.regenciesName}
                            </option>
                          ))}
                        </select>
                      )}
                    />
                    {errors.domicilies?.[key]?.regenciesId && (
                      <div className="invalid-feedback">
                        {errors.domicilies[key].regenciesId.message}
                      </div>
                    )}
                  </div>

                  {/* Address Input */}
                  <div className="col-12">
                    <label className="form-label">
                      Alamat Lengkap Mahasiswa (Berdasarkan{" "}
                      {value.statusAddress === "CURRENT" ? "SAAT INI" : value.statusAddress})
                      {" * "}
                    </label>
                    <Controller
                      name={`domicilies[${key}].completeAddress`}
                      control={control}
                      rules={{
                        required: "Alamat Lengkap is required",
                      }}
                      render={({ field }) => (
                        <input
                          {...field}
                          type="text"
                          className={`form-control ${errors.domicilies?.[key]?.completeAddress
                            ? "is-invalid"
                            : ""
                            }`}
                          placeholder="Masukkan Alamat"
                        />
                      )}
                    />
                    {errors.domicilies?.[key]?.completeAddress && (
                      <div className="invalid-feedback">
                        {errors.domicilies[key].completeAddress.message}
                      </div>
                    )}
                  </div>
                </React.Fragment>
              ))}

            </div>

          </div>
        </div>

        <div className='card'>
          <div className="card-body">
            <h4 className="mb-3">Universitas</h4>

            <div className="row g-3">

              <div className="col-12 col-md-5">
                <label htmlFor="address" className="form-label">NIM *</label>
                <input
                  {...register("nim", { required: 'NIM wajib diisi!' })}
                  className={getInputClassName('nim')}
                  type="text"
                  placeholder=''
                />
                <div className="invalid-feedback">{errors.nim?.message}</div>
                <div className="valid-feedback">Looks good!</div>
              </div>

              <div className="col-6 col-md-3">
                <label htmlFor="address" className="form-label">IPK *</label>
                <input
                  {...register("gradePointAverage", { required: 'IPK wajib diisi!', max: { value: 4, message: 'Maksimal 4' } })}
                  className={getInputClassName('gradePointAverage')}
                  type="text"
                  placeholder='Contoh 3.5'
                />
                <div className="invalid-feedback">{errors.gradePointAverage?.message}</div>
                <div className="valid-feedback">Looks good!</div>
              </div>

              <div className="col-6 col-md-4">
                <label htmlFor="jenjang_pendidikan" className="form-label">Semester *</label>
                <select
                  {...register("semester", { required: 'Semester wajib dipilih!' })}
                  className={getSelectClassName('semester')}
                >
                  <option value="" defaultValue>Pilih Semester</option>
                  <option value="4">4</option>
                  <option value="5">5</option>
                  <option value="6">6</option>
                  <option value="7">7</option>
                  <option value="8">8</option>
                </select>
                <div className="invalid-feedback">{errors.semester?.message}</div>
                <div className="valid-feedback">Looks good!</div>
              </div>

              <div className="col-12 col-md-6">
                <label htmlFor="address" className="form-label">Asal Universitas</label>
                <div class="input-group">
                  <input onChange={(e) => setSearchUniversities(e.target.value)} value={searchUniversities} type="text" className="form-control" placeholder='Cari Universitas' />
                  <select onChange={(e) => onHandlerGetMajorByUniversities({ id: e.target.value })} class="form-select" id="inputGroupSelect04" aria-label="Example select with button addon">
                    {!universities.length > 0 ? (
                      <option>Universitas tidak ditemukan!</option>
                    ) : (
                      <option>Pilih Universitas</option>
                    )}
                    {universities.map((value, key) => (
                      <option key={key} value={value.universitiesId}>{value.universitiesName}</option>
                    ))}
                  </select>
                </div>
              </div>

              <div className="col-12 col-md-6">
                <label htmlFor="address" className="form-label">Program Studi</label>
                <Controller
                  name={`universitiesMajorId`}
                  control={control}
                  rules={{
                    required: "Program studi wajib dipilih!",
                  }}
                  render={({ field }) => (
                    <select
                      {...field}
                      className={getSelectClassName('universitiesMajorId')}
                      disabled={!majorUniversities.length > 0}
                      onChange={(e) => {
                        const universitiesMajorId = e.target.value;
                        setValue(`universitiesMajorId`, parseInt(universitiesMajorId), { shouldValidate: true });
                      }}
                    >
                      {/* <option value="">Pilih Program Studi</option> */}
                      {majorUniversities?.map((value, idx) => (
                        <option
                          key={idx}
                          value={value.universitiesMajorId}
                        >
                          {value.universitiesMajor}
                        </option>
                      ))}
                    </select>
                  )}
                />
                <div className="invalid-feedback">{errors.universitiesMajorId?.message}</div>
                <div className="valid-feedback">Looks good!</div>
              </div>

              <div className="col-12 col-md-6">
                <label htmlFor="jenjang_pendidikan" className="form-label">Jenjang Pendidikan *</label>
                <select
                  {...register("educationalLevel", { required: 'Jenjang Pendidikan wajib dipilih!' })}
                  className={getSelectClassName('educationalLevel')}
                >
                  <option value="" defaultValue>Pilih Jenjang Pendidikan</option>
                  <option value="D3">D3</option>
                  <option value="D4">D4</option>
                  <option value="S1">S1</option>
                </select>
                <div className="invalid-feedback">{errors.educationalLevel?.message}</div>
                <div className="valid-feedback">Looks good!</div>
              </div>

              <div className="col-12 col-md-6">
                <label className="form-label">Tahun Angkatan Kuliah *</label>
                <select
                  {...register("collageYear", { required: 'Tahun Angkatan Kuliah wajib dipilih!' })}
                  className={getSelectClassName('collageYear')}
                >
                  <option value="">Pilih Tahun Angkatan Kuliah</option>
                  <option value="2018">2018</option>
                  <option value="2019">2019</option>
                  <option value="2020">2020</option>
                  <option value="2021">2021</option>
                  <option value="2022">2022</option>
                  <option value="2023">2023</option>
                </select>
                <div className="invalid-feedback">{errors.collageYear?.message}</div>
                <div className="valid-feedback">Looks good!</div>
              </div>

            </div>

          </div>
        </div>

        <button class="w-100 btn btn-primary" type="submit">
          {isLoading ? (
            <>
              <span class="spinner-border spinner-border-sm" aria-hidden="true"></span>
              <span class="visually-hidden" role="status">Loading...</span>
            </>
          ) : (
            <span>Selanjutnya </span>
          )}
        </button>

      </div >
    </form >
  )
}

export default Index;
