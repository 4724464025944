import React, { useState, useEffect } from 'react';
import api from '../../../utils/api';
import { useNavigate, useParams } from 'react-router-dom';
import { useForm, Controller } from 'react-hook-form';
import Select from "react-select";

const UniversitiesUpdate = () => {
  const { id } = useParams();

  const navigate = useNavigate();
  const [availableMajors, setAvailableMajors] = useState([]);

  const { control, register, handleSubmit, setValue, reset } = useForm();

  const [isLoading, setIsloading] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      // Fetch university details
      const universityResponse = await api.adminGetUniversitiesDetail({ id });
      reset({
        universitiesName: universityResponse.universitiesName,
        universitiesType: universityResponse.universitiesType,
        universitiesProvincesId: universityResponse.domicilies.provinceId,
        universitiesRegenciesId: universityResponse.domicilies.regenciesId,
      });

      // Pre-load available majors
      fetchMajors();

      // Pre-select majors already in university data
      const selectedMajors = universityResponse.major.map((m) => ({
        value: m.majorId,
        label: m.majorName,
      }));
      setValue("majorId", selectedMajors);
    }

    fetchData();
  }, [id, reset, setValue]);

  // Function to fetch majors with search query (No debounce)
  const fetchMajors = async (query = "") => {
    try {
      const majorResponse = await api.adminGetMajor({ q: query, page: 1, pageSize: 25 });
      setAvailableMajors(
        majorResponse?.major?.map((m) => ({
          value: m.majorId,
          label: m.majorName,
        }))
      );
    } catch (err) {
      console.error("Failed to fetch majors", err);
    }
  };

  const handleSearch = (inputValue) => {
    fetchMajors(inputValue);
  };

  const onHandleSubmit = async (formData) => {
    setIsloading(true);
    const { status, message } = await api.adminUpdateUniversities({
      payload: {
        ...formData,
        majorId: formData.majorId.map((m) => m.value), // Ensure major IDs are numbers
      },
      id,
    });
    if (status !== 'success') {
      alert(message);
    } else {
      alert(message);
      navigate(`/admin/universities/${id}`)
    }
    setIsloading(false);
  }

  return (
    <form onSubmit={handleSubmit(onHandleSubmit)}>
      <div className="card">
        <div className="card-body">
          <h1 className='h5'>Update</h1>
          <div className="row g-3">

            <div className="col-8">
              <label className="form-label">Universitas</label>
              <input
                className="form-control"
                type="text"
                {...register("universitiesName")}
              />
            </div>

            <div className="col-4">
              <label className="form-label">Type</label>
              <select
                className="form-select"
                {...register("universitiesType")}
              >
                <option value="PTS">PTS</option>
                <option value="PTN">PTN</option>
              </select>
            </div>

            <div className="col-6">
              <label className="form-label">Provinsi</label>
              <input
                disabled
                className="form-control"
                type="text"
                {...register("universitiesProvincesId")}
              />
            </div>

            <div className="col-6">
              <label className="form-label">Kota</label>
              <input
                disabled
                className="form-control"
                type="text"
                {...register("universitiesRegenciesId")}
              />
            </div>

            <hr />

            {/* Majors Multi-Select with Dynamic Search */}
            <div className="mb-3">
              <label className="form-label">Pilih Jurusan</label>
              <Controller
                name="majorId"
                control={control}
                render={({ field }) => (
                  <Select
                    {...field}
                    options={availableMajors}
                    isMulti
                    isSearchable
                    onInputChange={handleSearch} // Calls API when user types
                    className="basic-multi-select"
                    classNamePrefix="select"
                  />
                )}
              />
            </div>

            <div className="col-12">
              <div className="d-grid gap-2">
                <button type="submit" disabled={isLoading} className='btn btn-primary' >
                  {isLoading ?
                    <>
                      <span class="spinner-border spinner-border-sm" aria-hidden="true"></span>
                      <span role="status"> Loading...</span>
                    </>
                    :
                    <>
                      <span>Ubah</span>
                    </>
                  }
                </button>
              </div>
            </div>

          </div>
        </div>
      </div>
    </form>
  )
}

export default UniversitiesUpdate;
