import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import api from '../../../utils/api';

const RegistrationsDetail = ({ authUser }) => {
  const role = authUser?.currentlyProgram?.role?.roleName?.toLowerCase();

  const [users, setUsers] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [loadingKeys, setLoadingKeys] = useState([]);
  const [refresh, setRefresh] = useState(false);
  const { id } = useParams();

  const navigate = useNavigate();

  useEffect(() => {
    const getRegistrationsDetail = async () => {
      const { status, message, data } = await api.getRegistrationsDetail({ id });
      if (status !== 'success') {
        alert(message);
        navigate(-1);
      }
      setUsers(data);
      console.log(data);
      setIsLoading(false);
      setRefresh(false);
    }

    getRegistrationsDetail();
  }, [id, navigate, refresh]);

  const onHandleUpdateStatusAcceptance = async (key, statusAcceptanceProgramId) => {
    setIsLoading(true);
    setLoadingKeys((prev) => [...prev, key]);
    const { status } = await api.mentorsUpdateStatusAcceptanceRegistrations({
      id,
      payload: {
        batchId: 3,
        statusAcceptanceProgramId,
      }
    });
    if (status === 'success') {
      setRefresh(true);
      setLoadingKeys((prev) => prev.filter((k) => k !== key));
    }
  }

  return (
    <div className='row'>
      <div className="col-8">
        {isLoading ?
          <div class="d-flex justify-content-center">
            <div class="spinner-border" role="status">
              <span class="visually-hidden">Loading...</span>
            </div>
          </div>
          :
          <div className="row g-3">

            <div className='card'>
              <div className="card-body">
                <h4 className="mb-3">Data Personal</h4>

                <div className="row g-3">

                  <div className="col-12">
                    <label htmlFor="username" className="form-label">Nama</label>
                    <div className="input-group has-validation">
                      <input disabled value={`${users?.firstName} ${users?.lastName}`} type="text" className="form-control" />
                    </div>
                  </div>

                  <div className="col-12">
                    <label htmlFor="email" className="form-label">Email</label>
                    <input disabled value={users?.email} type="email" className="form-control" id="email" placeholder="you@example.com" />
                  </div>

                  <div className="col-12">
                    <label htmlFor="address" className="form-label">No HP</label>
                    <input disabled value={users?.numberPhone} type="text" className="form-control" id="address" />
                  </div>

                  {users?.domicilies?.map((value, key) => (
                    <>
                      <div className="col-6" key={key}>
                        <label htmlFor="country" className="form-label">Kota Domisili {value.statusAddress === "CURRENT" ? "SAAT INI" : value.statusAddress}</label>
                        <input disabled value={value?.regenciesName} type="text" className="form-control" />
                      </div>

                      <div className="col-6" key={key}>
                        <label htmlFor="country" className="form-label">Provinsi Domisili {value.statusAddress === "CURRENT" ? "SAAT INI" : value.statusAddress}</label>
                        <input disabled value={value?.provinciesName} type="text" className="form-control" />
                      </div>

                      <div className="col-12">
                        <label htmlFor="address" className="form-label">
                          Alamat Lengkap Mahasiswa (Berdasarkan {value.statusAddress === "CURRENT" ? "SAAT INI" : value.statusAddress})
                        </label>
                        <input disabled value={value?.completeAddress} type="text" className="form-control" />
                      </div>
                    </>
                  ))}

                </div>
              </div>
            </div>

            <div className="card">
              <div className="card-body">
                <h4 className="mb-3">Berkas</h4>

                <div className="row g-3">

                  <div className="col-12">
                    <label htmlFor="address" className="form-label">Curriculum Vitae</label>
                    <input disabled value={users?.curriculumVitae} type="text" className="form-control" id="address" />
                  </div>

                  <div className="col-12">
                    <label htmlFor="address" className="form-label">Academic Transcript</label>
                    <input disabled value={users?.academicTranscript} type="text" className="form-control" id="address" />
                  </div>

                  <div className="col-12">
                    <label htmlFor="address" className="form-label">Portfolio</label>
                    <input disabled value={users?.portfolio} type="text" className="form-control" id="address" />
                  </div>

                  <div className="col-12">
                    <label htmlFor="address" className="form-label">Surat Eligibility dari Universitas</label>
                    <input disabled value={users?.eligibilityFromUniversity} type="text" className="form-control" id="address" />
                  </div>

                  <div className="col-12">
                    <label htmlFor="address" className="form-label">Organization Certificate One</label>
                    <input disabled value={users?.organizationCertificateOne} type="text" className="form-control" id="address" />
                  </div>

                </div>
              </div>
            </div>

            <div className='card'>
              <div className='card-body'>
                <h4 className="mb-3">Universitas</h4>

                <div className="row g-3">

                  <div className="col-12">
                    <label htmlFor="address" className="form-label">Asal Universitas</label>
                    <input disabled value={users?.universities?.universitiesName} type="text" className="form-control" />
                  </div>

                  <div className="col-12">
                    <label htmlFor="address" className="form-label">Program Studi</label>
                    <input disabled value={users?.universities?.universitiesMajor} type="text" className="form-control" />
                  </div>

                  <div className="col-12">
                    <label htmlFor="address" className="form-label">Jenjang Pendidikan</label>
                    <input disabled value={users?.universities?.educationLevel} type="text" className="form-control" />
                  </div>

                  <div className="col-6">
                    <label htmlFor="address" className="form-label">Tahun Angkatan Kuliah</label>
                    <input disabled value={users?.universities?.collageYear} type="text" className="form-control" />
                  </div>

                  <div className="col-6">
                    <label htmlFor="address" className="form-label">Semester</label>
                    <input disabled value={users?.semester} type="text" className="form-control" />
                  </div>

                </div>

              </div>
            </div>

            <div className='card'>
              <div className='card-body'>
                <h4 className="mb-3">Program</h4>

                <div className="row g-3">
                  <div className="col-6">
                    <label htmlFor="address" className="form-label">Program</label>
                    <input disabled value={users?.thirdTierProgramName} type="text" className="form-control" />
                  </div>

                  <div className="col-6">
                    <label htmlFor="address" className="form-label">Cluster</label>
                    <input disabled value={users?.institutions?.institutionsClusterFocus} type="text" className="form-control" />
                  </div>

                  <div className="col-6">
                    <label htmlFor="address" className="form-label">Provinsi Penempatan</label>
                    <input disabled value={users?.institutions?.institutionsLocation} type="text" className="form-control" />
                  </div>

                  <div className="col-6">
                    <label htmlFor="address" className="form-label">Kab/Kota Penempatan</label>
                    <input disabled value={users?.regenciesUsersLocation} type="text" className="form-control" />
                  </div>

                  <div className="col-12">
                    <label htmlFor="address" className="form-label">Lembaga Mitra Penempatan Magang</label>
                    <input disabled value={users?.institutions?.institutionsName} type="text" className="form-control" />
                  </div>

                  <div className="col-12">
                    <label htmlFor="address" className="form-label">Lembaga Divisi/Bidang</label>
                    <input disabled value={users?.institutions?.institutionsDivisionName} type="text" className="form-control" />
                  </div>
                </div>

              </div>
            </div>

          </div>
        }
      </div>

      <div className="col-4">
        <div className="card">
          <div className="card-body">
            <h4 className="mb-3">Status Penerimaan</h4>

            <div class="list-group">

              {users?.statusAcceptance?.map((value, key) => (
                <label class="list-group-item d-flex gap-2" key={key}>

                  {loadingKeys.includes(key) ? (
                    <div class="spinner-border spinner-border-sm" role="status">
                      <span class="visually-hidden">Loading...</span>
                    </div>
                  ) : (
                    <input
                      disabled={!value.accessMentor || role === 'admin'}
                      onClick={() => onHandleUpdateStatusAcceptance(key, value.statusAcceptanceProgramId)}
                      class="form-check-input flex-shrink-0" type="checkbox"
                      value={value.statusAcceptanceProgramId}
                      checked={value.isChecked}
                    />
                  )}

                  <span>
                    {value.name}
                    <small class="d-block text-body-secondary">
                      Last Updated: {' '}
                      <strong>
                        {value.lastUpdated}
                      </strong>
                    </small>
                    <small class="d-block text-body-secondary">
                      Updated By: {' '}
                      <strong>
                        {`${value?.updatedBy?.firstName} ${value?.updatedBy?.lastName}`}
                      </strong>
                    </small>
                  </span>
                </label>
              ))}

            </div>
          </div>
        </div>
      </div>
    </div >
  )
}

export default RegistrationsDetail