import React, { useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { loginAction } from '../../utils/action';
import ToastNotifications from '../../Components/ToastNotifications';
import api from '../../utils/api';
import { useForm } from 'react-hook-form';

const Login = ({ setAuthUser }) => {
  const navigate = useNavigate();
  const { register, handleSubmit, formState: { errors, touchedFields } } = useForm();

  const [isLoading, setIsLoading] = useState(false);

  const [email, setEmail] = useState();
  const [password, setPassword] = useState();

  const [messageNotifications, setMessage] = useState('');
  const [show, setShow] = useState(false);

  const onHandleLogin = async (data) => {
    setIsLoading(true);
    const { status, message, data: authUsers } = await loginAction(data)
    setIsLoading(false);
    if (status === 'success') {
      const offeredProgram = await api.getOwnOfferedProgram();
      const data = {
        ...authUsers,
        offeredProgram,
      }
      setAuthUser(data);
      switch (data?.currentlyProgram?.role?.roleName) {
        case 'Mentor':
          navigate('/mentor');
          break;
        case 'Mahasiswa':
          navigate('/app');
          break;
        default:
          navigate('/program');
          break;
      }
    } else {
      setMessage(message);
      setShow(true);
    }
  };

  const getInputClassName = (fieldName) => {
    if (!touchedFields[fieldName]) return 'form-control'; // Initial render
    return `form-control ${errors[fieldName] ? 'is-invalid' : 'is-valid'}`;
  };

  return (
    <>
      <div class="row align-items-center g-lg-5 py-5">
        <div class="col-lg-7 text-center text-lg-start">
          <h1 class="display-4 fw-bold lh-1 text-body-emphasis mb-3">Masuk</h1>
          <p class="col-lg-10 fs-5">Kamu bisa masuk menggunakan akun yang pernah mendaftarkan program:</p>
          <p>CLP (Campus Leaders Program)</p>
          <p>LEAD (Leadership Experience Development)</p>
          <p>HOL (Home Of Leaders)</p>
        </div>
        <div class="col-md-10 mx-auto col-lg-5">
          <form onSubmit={handleSubmit(onHandleLogin)} class="p-4 p-md-5 border rounded-3 bg-body-tertiary needs-validation" noValidate>
            <div class="form-floating mb-3">
              <input {...register("email", { required: 'Email tidak boleh kosong' })} type="email" className={getInputClassName('email')} id="floatingInput" placeholder="name@example.com" />
              <label for="floatingInput">Email</label>
              <div className="invalid-feedback">{errors.email?.message}</div>
              <div className="valid-feedback">Looks good!</div>
            </div>
            <div class="form-floating mb-3">
              <input {...register("password", {
                required: 'Password wajib diisi!',
                minLength: {
                  value: 8,
                  message: 'Minimal 8 karakter',
                },
              })} type="password" className={getInputClassName('password')} id="floatingPassword" placeholder="Password" />
              <label for="floatingPassword">Password</label>
              <div className="invalid-feedback">{errors.password?.message}</div>
              <div className="valid-feedback">Looks good!</div>
            </div>
            {/* <div class="checkbox mb-3">
            <label>
              <input type="checkbox" value="remember-me" /> Remember me
            </label>
          </div> */}
            <button class="w-100 btn btn-lg btn-primary" type="submit">
              {isLoading ? (
                <>
                  <span class="spinner-border spinner-border-sm" aria-hidden="true"></span>
                  <span class="visually-hidden" role="status">Loading...</span>
                </>
              ) : (
                <span>Masuk</span>
              )}
            </button>
            <hr class="my-4" />
            {/* <small class="text-body-secondary">Kamu Lupa <Link to="/forget-password">Password?</Link></small> */}
            <div class="d-flex justify-content-between">
              <small class="text-body-secondary">Belum punya akun? <Link to="/register">Daftar</Link></small>
              <small class="text-body-secondary"><Link to="/forget-password">Lupa password? </Link></small>
            </div>
            <hr></hr>
          </form>
        </div>
      </div>
      <ToastNotifications setShowNotifications={setShow} show={show} message={messageNotifications} />
    </>
  )
}

export default Login;
