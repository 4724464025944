import React, { useState, useEffect } from 'react';
import api from '../../../utils/api';
import { listFriendsPAT } from '../../../utils/data';
import { useNavigate } from 'react-router-dom';

const EvaluationList = ({ authUser }) => {
  const navigate = useNavigate();
  const { batchId = 1 } = authUser?.currentlyProgram.batch || {};

  const [loading, setLoading] = useState(true);
  const [evaluates, setEvaluates] = useState(listFriendsPAT);
  const [message, setMessage] = useState();
  const [status, setStatus] = useState(true);

  useEffect(() => {
    const getPATFriends = async () => {
      const { status, message, data } = await api.appGetFriendsDivisiPAT({ batchId });
      if (status !== 'success') {
        setMessage(message);
        setStatus(false);
      }
      setEvaluates(data);
      setLoading(false);
    }

    getPATFriends();
  }, []);

  if (loading) {
    return (
      <div class="d-flex justify-content-center">
        <div class="spinner-border" role="status">
          <span class="visually-hidden">Loading...</span>
        </div>
      </div>
    )
  }

  if (!status) {
    return (
      <div class="d-flex justify-content-center">
        {message}
      </div>
    )
  }

  return (
    <div className='card'>
      <div className="card-body table-responsive">
        <h1 className='h5'>
          Teman Satu Divisi
        </h1>
        <table className="table table-hover">
          <thead>
            <tr>
              <th scope="col">#</th>
              <th scope="col">Nama</th>
              <th scope="col">Status</th>
              <th scope="col">Aksi</th>
            </tr>
          </thead>
          <tbody>
            {loading ?
              <>
                {evaluates?.map(() => (
                  <tr className='placeholder-glow'>
                    <th><span className='placeholder col-12'></span></th>
                    <td><span className='placeholder col-12'></span></td>
                    <td><span className='placeholder col-12'></span></td>
                    <td><span className='placeholder col-12 bg-primary'></span></td>
                  </tr>
                ))}
              </>
              :
              <>
                {evaluates?.map((value, key) => (
                  <tr key={key} style={{ cursor: 'default' }}>
                    <th scope="row">{key + 1}</th>
                    <td>{`${value.firstName} ${value.lastName}`}</td>
                    <td>{value.hasEvaluated ?
                      <span class="badge rounded-pill text-bg-success">Sudah dinilai</span> :
                      <span class="badge rounded-pill text-bg-warning">Belum Menilai</span>}
                    </td>
                    <td><button disabled={value.hasEvaluated} onClick={() => navigate(`/app/evaluation/${value.usersClPId}/evaluate`)} className='btn btn-primary badge rounded-pill'>Nilai</button></td>
                  </tr>
                ))}
              </>
            }
          </tbody>
        </table>
      </div>
    </div >
  )
}

export default EvaluationList