import React, { useState, useEffect } from 'react'
import api from '../../utils/api';

const Index = ({ authUser }) => {
  const { batch, batchId } = authUser?.currentlyProgram?.batch || { batchId: 3, batch: 10 };

  const [timeline, setTimeline] = useState([]);

  useEffect(() => {
    const getTimelineByBatchId = async () => {
      const data = await api.getTimelineByBatchId({ batchId });
      setTimeline(data);
    }

    getTimelineByBatchId();
  }, [batchId]);

  return (
    <div className='row g-3'>

      <div className="col-12">
        <div className="card">
          <div className="card-body">
            <div className="d-flex justify-content-between">
              <h1 className='h5'>Dashboard</h1>
            </div>
          </div>
        </div>
      </div>

      <div className="col-6">
        <div className="card">
          <div className="card-body">
            <h1 className='h5'>Timeline Magang CLP {batch}</h1>

            <div class="list-group">
              {timeline.map((value, key) => (
                <label class={`list-group-item d-flex gap-3 py-3 ${!value.activeStatus.status && 'bg-body-tertiary'}`} key={key}>
                  <div className="d-flex gap-2 w-100 justify-content-between">
                    <span class="pt-1 form-checked-content">
                      <strong>{value.title}</strong>
                      <small class="d-block text-body-secondary">
                        {value.startDate} - {value.endDate}
                      </small>
                    </span>
                    <div className="d-flex align-items-center">
                      <span class={`badge rounded-pill ${value.activeStatus.status ? 'text-bg-primary' : 'text-bg-success'}`}>
                        {value.activeStatus.message}
                      </span>
                    </div>
                  </div>
                </label>
              ))}
            </div>

          </div>
        </div>
      </div>

    </div>
  )
}

export default Index