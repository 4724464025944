import React, { useState, useEffect } from 'react';
import api from '../../../utils/api';
import { Link, useParams } from 'react-router-dom';

const UniversitiesDetail = () => {
  const { id } = useParams();
  const [universities, setUniversities] = useState({});

  const [isLoading, setIsloading] = useState(true);

  useEffect(() => {
    const getInstitutionsById = async () => {
      const data = await api.adminGetUniversitiesDetail({ id });
      setUniversities(data);
      setIsloading(false);
    }

    getInstitutionsById();
  }, [id]);

  if (isLoading) {
    return <>
      <div class="d-flex justify-content-center">
        <div class="spinner-border" role="status">
          <span class="visually-hidden">Loading...</span>
        </div>
      </div>
    </>
  }

  return (
    <div className='card'>
      <div className='card-body'>
        <div className="d-flex justify-content-between mb-3">
          <h1 className="h5">Universitas</h1>
          <div className="col-2">
            <div className="d-grid gap-2">
              <Link to={`/admin/universities/${id}/update`} className='btn btn-primary'>Ubah</Link>
            </div>
          </div>
        </div>

        <div className="row g-3">
          <div className="col-12">
            <label htmlFor="Nama" className="form-label">Nama</label>
            <div className="input-group has-validation">
              <input disabled value={universities?.universitiesName} type="text" className="form-control" id="Nama" placeholder="Nama" required />
            </div>
          </div>

          <div className="col-12">
            <label htmlFor="Nama" className="form-label">Type</label>
            <div className="input-group has-validation">
              <input disabled value={universities?.universitiesType} type="text" className="form-control" id="Nama" placeholder="Nama" required />
            </div>
          </div>

          <div className="col-6">
            <label htmlFor="Nama" className="form-label">Kota</label>
            <div className="input-group has-validation">
              <input disabled value={universities?.domicilies?.regenciesName} type="text" className="form-control" id="Nama" placeholder="Nama" required />
            </div>
          </div>

          <div className="col-6">
            <label htmlFor="Nama" className="form-label">Provinsi</label>
            <div className="input-group has-validation">
              <input disabled value={universities?.domicilies?.provinceName} type="text" className="form-control" id="Nama" placeholder="Nama" required />
            </div>
          </div>

          {!universities.major.length > 0 ? (
            <>
              <div class="alert alert-primary text-center" role="alert">
                Jurusan belum ada!
              </div>
            </>
          ) : (
            <>
              <table className='table table-hover'>
                <thead>
                  <tr>
                    <th scope="col">#</th>
                    <th scope="col">Jurusan</th>
                  </tr>
                </thead>
                <tbody>
                  {universities?.major?.map((value, key) => (
                    <tr key={key} style={{ cursor: 'default' }}>
                      <th scope="row">{key + 1}</th>
                      <td>{value.majorName}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </>
          )}

        </div>

      </div>
    </div>
  )
}

export default UniversitiesDetail;
