import React, { useState, useEffect } from 'react';
import api from '../../../utils/api';
import { useNavigate, useParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';

const ClassificationsUpdate = () => {
  const navigate = useNavigate();
  const { divisionInstitutionsId } = useParams();

  const { register, setValue, watch, handleSubmit } = useForm({
    defaultValues: {
      classifications: [],
    },
  });

  const classifications = watch("classifications", []);

  const [isLoading, setIsloading] = useState(false);

  useEffect(() => {
    const getInstitutions = async () => {
      const data = await api.adminGetClassficationsHardskillByDivisiLembagaId({ id: divisionInstitutionsId });

      if (data?.hardskill) {
        data.hardskill.forEach((item, index) => {
          setValue(`classifications.${index}.classificationId`, item.classificationId);
          setValue(`classifications.${index}.classification`, item.classification);
        });
        setValue(`institutionsName`, data?.institutions?.institutionsName);
        setValue(`divisionName`, data?.institutions?.divisionName);
        setValue(`batch`, data?.batch?.batch);
      }
    }

    getInstitutions();
  }, []);

  const onSubmit = async (data) => {
    setIsloading(true);
    const { status, message } = await api.adminUpdateClassifications({
      divisionInstitutionsId: divisionInstitutionsId,
      payload: data,
    });
    if (status !== 'success') {
      alert(message);
    } else {
      alert(message);
      navigate('/admin/classifications')
    }
    setIsloading(false);
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="card">
        <div className="card-body">
          <h1 className='h5'>Ubah KPI</h1>
          <div className="row g-3">

            <div className="col-5">
              <label className="form-label">Lembaga</label>
              <input disabled {...register('institutionsName')} type="text" className="form-control" />
            </div>

            <div className="col-5">
              <label className="form-label">Divisi</label>
              <input disabled {...register('divisionName')} type="text" className="form-control" />
            </div>

            <div className="col-2">
              <label className="form-label">Batch</label>
              <input disabled {...register('batch')} type="text" className="form-control" />
            </div>

            {classifications?.map((value, key) => (
              <div className="col-12">
                <label className="form-label">KPI {key + 1}</label>
                <textarea {...register(`classifications.${key}.classification`)} rows={3} type="text" className="form-control" />
              </div>
            ))}

            <div className="col-12">
              <div className="d-grid gap-2">
                <button type="submit" disabled={isLoading} className='btn btn-primary'>
                  {isLoading ?
                    <>
                      <span class="spinner-border spinner-border-sm" aria-hidden="true"></span>
                      <span role="status"> Loading...</span>
                    </>
                    :
                    <>
                      <span>Ubah</span>
                    </>
                  }
                </button>
              </div>
            </div>

          </div>
        </div>
      </div>
    </form>
  )
}

export default ClassificationsUpdate;
