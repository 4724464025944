import React, { useState, useEffect } from 'react';
import api from '../../../utils/api';
import { Link, useNavigate, useParams } from 'react-router-dom';

const Detail = () => {
  const { id } = useParams();
  const [institutions, setInstitutions] = useState();
  const navigate = useNavigate();

  const [isLoading, setIsloading] = useState(true);

  useEffect(() => {
    const getInstitutionsById = async () => {
      const data = await api.adminGetInstitutionsDetailById({ id });
      setInstitutions(data);
      setIsloading(false);
    }

    getInstitutionsById();
  }, [id]);

  if (isLoading) {
    return <>
      <div class="d-flex justify-content-center">
        <div class="spinner-border" role="status">
          <span class="visually-hidden">Loading...</span>
        </div>
      </div>
    </>
  }

  return (
    <div className='card'>
      <div className='card-body'>
        <div className="d-flex justify-content-between mb-3">
          <h1 className="h5">Lembaga</h1>
          <div className="col-2">
            <div className="d-grid gap-2">
              <Link to={`/admin/institutions/${id}/add`} className='btn btn-primary'>Tambah Divisi</Link>
            </div>
          </div>
        </div>

        <div className="row g-3">
          <div className="col-12">
            <label htmlFor="Nama" className="form-label">Nama</label>
            <div className="input-group has-validation">
              <input disabled value={institutions?.institutionsName} type="text" className="form-control" id="Nama" placeholder="Nama" required />
            </div>
          </div>

          <div className="col-12">
            <label htmlFor="Nama" className="form-label">Lokasi</label>
            <div className="input-group has-validation">
              <input disabled value={institutions?.institutionsLocation} type="text" className="form-control" id="Nama" placeholder="Nama" required />
            </div>
          </div>

          {!institutions.institutionsDivisions.length > 0 ? (
            <>
              <div class="alert alert-primary text-center" role="alert">
                Divisi belum ada!
              </div>
            </>
          ) : (
            <>
              {institutions?.institutionsDivisions?.map((value, key) => (
                <div key={key} className="col-12">
                  <div key={key} className="row g-3">

                    <div className="col-2">
                      <label htmlFor="Divisi" className="form-label">Batch</label>
                      <div className="input-group has-validation">
                        <input disabled value={value?.batch} type="text" className="form-control" />
                      </div>
                    </div>

                    <div className="col-5">
                      <label htmlFor="Divisi" className="form-label">Start Date</label>
                      <div className="input-group has-validation">
                        <input disabled value={value?.startDate} type="text" className="form-control" />
                      </div>
                    </div>

                    <div className="col-5">
                      <label htmlFor="Divisi" className="form-label">End Date</label>
                      <div className="input-group has-validation">
                        <input disabled value={value?.endDate} type="text" className="form-control" />
                      </div>
                    </div>

                    <div className="col-12 table-responsive">
                      <table className='table table-hover'>
                        <thead>
                          <tr>
                            <th scope="col">#</th>
                            <th scope="col">Nama</th>
                          </tr>
                        </thead>
                        <tbody>
                          {value.divisions.map((value, key) => (
                            <tr key={key} onClick={() => navigate(`/admin/institutions/${id}/division/${value?.divisionInstitutionId}`)} style={{ cursor: 'pointer' }}>
                              <th scope="row">{key + 1}</th>
                              <td>{value.divisionName}</td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              ))}
            </>
          )}

        </div>

      </div>
    </div>
  )
}

export default Detail;
