import React, { useState, useEffect } from 'react';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import api from '../../../utils/api';

const UniversitiesList = () => {
  const navigate = useNavigate();
  const [isLoading, setIsloading] = useState(true);
  const [universities, setUniversities] = useState([]);

  const [searchParams, setSearchParams] = useSearchParams();
  const [currentPage] = useState(searchParams.get("page") || 1);
  const [itemsPerPage] = useState(searchParams.get("pageSize") || 50)
  const [query, setQuery] = useState(searchParams.get("q") || "");

  useEffect(() => {
    if (!searchParams.has('pageSize')) {
      setSearchParams({ ...searchParams, pageSize: itemsPerPage });
      navigate(`?q=${query}&page=${currentPage}&pageSize=${itemsPerPage}`);
    }

    const getUniversitiesHandler = async () => {
      const data = await api.adminGetUniversities({ q: query, page: currentPage, pageSize: itemsPerPage });
      setUniversities(data);
      setIsloading(false)
    }

    getUniversitiesHandler();
  }, [currentPage, itemsPerPage, navigate, query, searchParams, setSearchParams]);

  const handleNavigateToPage = (pageNumber) => {
    setSearchParams({ ...searchParams, page: pageNumber });
    navigate(`?q=${query}&page=${pageNumber}&pageSize=${itemsPerPage}`);
  }

  const handlePageSize = (pageSize) => {
    setSearchParams({ ...searchParams, pageSize: pageSize });
    navigate(`?q=${query}&page=${currentPage}&pageSize=${pageSize}`)
  }

  return (
    <div className='card'>
      <div className="card-body table-responsive">
        <div className="d-flex justify-content-between mb-3">
          <h1 className='h5'>Universitas</h1>
          <form class="w-100 me-3 mx-3" role="search">
            <input
              onChange={(e) => setQuery(e.target.value)}
              value={query}
              type="search" class="form-control" placeholder="Search..." aria-label="Search"
            />
          </form>
          <div className="col-2">
            <div className="d-grid gap-2">
              <button disabled onClick={() => navigate("/admin/universities/add")} className='btn btn-primary'>Tambah</button>
            </div>
          </div>
        </div>

        <table className='table table-hover'>
          <thead>
            <tr>
              <th scope="col">#</th>
              <th scope="col">Nama</th>
              <th scope="col">Type</th>
              <th scope="col">Domisili</th>
            </tr>
          </thead>
          <tbody>
            {isLoading ?
              <>
                {Array.apply(null, Array(Number(itemsPerPage))).map(() => (
                  <tr className='placeholder-glow'>
                    <th><span className='placeholder col-12'></span></th>
                    <td><span className='placeholder col-12'></span></td>
                    <td><span className='placeholder col-12'></span></td>
                    <td><span className='placeholder col-12'></span></td>
                  </tr>
                ))}
              </>
              :
              <>
                {universities?.universities?.map((value, key) => (
                  <tr key={key} onClick={() => navigate(`/admin/universities/${value.universitiesId}`)} style={{ cursor: 'pointer' }}>
                    <th scope="row">{(currentPage - 1) * itemsPerPage + (key + 1)}</th>
                    <td>{value.universitiesName}</td>
                    <td>{value.universitiesType}</td>
                    <td>{`${value.domicilies.regenciesName}, ${value.domicilies.provinceName}`}</td>
                  </tr>
                ))}
              </>
            }
          </tbody>
        </table>

        <nav aria-label="Page navigation example">
          <ul class="pagination justify-content-center">
            <li class="page-item">
              <button onClick={() => handleNavigateToPage(universities.previous)} class={`page-link ${!universities.previous && 'disabled'}`}>Previous</button>
            </li>
            <li class="page-item"><p class="page-link">{universities.current + 1 || parseInt(currentPage)}</p></li>
            <li class="page-item">
              <button onClick={() => handleNavigateToPage(universities.next + 1)} class={`page-link ${!universities.next && 'disabled'}`}>Next</button>
            </li>
          </ul>
        </nav>

      </div>
    </div >
  )
}

export default UniversitiesList;
