import React from 'react';
import { Link } from 'react-router-dom';

const Home = () => {
  return (
    <>
      <section>
        <h2 className='text-center'>Welcome The Future SDGs Heroes!</h2>
        <img className='img-fluid rounded' src='https://firebasestorage.googleapis.com/v0/b/bcf-clp-app.appspot.com/o/landing-page-clp-10%2FDSC_7647.webp?alt=media&token=9fd0f9a5-1c87-41de-b667-c8372bfee3b7' alt='Be Ready for Campus Leaders Program batch 10' />
        <h3 className='text-center mt-3'>Be Ready for Campus Leaders Program batch 10</h3>

        <hr></hr>

        <p>
          Campus Leaders Program merupakan program <b>pengembangan kepimpinan dan kapasitas mahasiswa</b> yang dikemas
          dalam kegiatan <b>Magang, Praktikum, dan <i>Volunteering</i></b>. Peserta Campus Leaders Program yang kami sebut sebagai SDGs Heroes.
          SDGs Heroes akan mendapatkan pengalaman untuk bisa langsung berkontribusi kepada masyarakat untuk membantu memecahkan
          permasalahan dalam bidang pendidikan, kesehatan, dan lingkungan.
        </p>
        <p>
          SDGs Heroes juga berperan penting dalam mewujudkan Indonesia yang berkelanjutan sesuai
          dengan 17 target dalam <b>Sustainable Development Goals (SDGs)</b>.
        </p>
      </section>

      <section>
        <div class="row g-4 py-5 row-cols-2 row-cols-lg-4 text-center">
          <div class="col">
            <div>
              <h3 class="fs-2 text-body-emphasis">42</h3>
              <p>Universitas Mitra</p>
            </div>
          </div>
          <div class="col">
            <div>
              <h3 class="fs-2 text-body-emphasis">28</h3>
              <p>Lembaga Sosial Mitra</p>
            </div>
          </div>
          <div class="col">
            <div>
              <h3 class="fs-2 text-body-emphasis">13</h3>
              <p>Provinsi Intervensi</p>
            </div>
          </div>
          <div class="col">
            <div>
              <h3 class="fs-2 text-body-emphasis">1.528</h3>
              <p>Mahasiswa yang Telah Menjadi SDGs Heroes</p>
            </div>
          </div>
        </div>
      </section>

      <section className='text-center'>
        <h3>PROSIDING HASIL KEGIATAN SDGs HEROES CAMPUS LEADERS PROGRAM</h3>
        <p>Mari simak kontribusi dan capaian nyata SDGs Heroes selama melaksanakan kegiatan Magang, Praktikum, maupun Volunteer bersama para praktisi professional (mentor) dan akademisi (dosen pembimbing) yang dituliskan dalam artikel ilmiah jurnal prosiding berikut.</p>
        <a href='http://s.id/ProsidingCLP9' target='_blank' rel="noreferrer"><b>s.id/ProsidingCLP9</b></a>

        <div class="row row-cols-1 row-cols-md-2 g-3" style={{ marginTop: 10 }}>
          <div className="col">
            <img className='img-fluid rounded' src='https://firebasestorage.googleapis.com/v0/b/bcf-clp-app.appspot.com/o/landing-page-clp-10%2FCover%20Prosiding%20CLP%207.webp?alt=media&token=022e33b3-986a-4e06-9c12-e3b630654e3f' alt='Cover Prosiding CLP 7' />
          </div>
          <div className="col">
            <img className='img-fluid rounded' src='https://firebasestorage.googleapis.com/v0/b/bcf-clp-app.appspot.com/o/landing-page-clp-10%2FCover%20Prosiding%20CLP%208.webp?alt=media&token=2256a005-5ac4-40ab-972e-b4b1f3f7b954' alt='Cover Prosiding CLP 8' />
          </div>
        </div>

      </section>

      <section style={{ marginTop: 30 }} className='text-center'>
        <div class="d-flex justify-content-center">
          <iframe className='rounded' width={600} height={400} src="https://www.youtube.com/embed/HnMBMVKNyYM?si=Y8okwhiHDKMeaANs" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
        </div>
      </section>

      <section className='text-center' style={{ marginTop: 30 }}>
        <h3>Timeline Campus Leaders Program Batch 10</h3>
        <div className="row row-cols-1 row-cols-md-2 g-3">
          <div className="col">
            <img className='img-fluid rounded' src='https://firebasestorage.googleapis.com/v0/b/bcf-clp-app.appspot.com/o/landing-page-clp-10%2FTimeline%201.webp?alt=media&token=205dd031-866e-4096-9672-995bf635beef' alt='Timeline Campus Leaders Program batch 10' />
          </div>
          <div className="col">
            <img className='img-fluid rounded' src='https://firebasestorage.googleapis.com/v0/b/bcf-clp-app.appspot.com/o/landing-page-clp-10%2FTimeline%202.webp?alt=media&token=f8a44734-03b2-44df-8000-12020b7d4e62' alt='Timeline Campus Leaders Program batch 10' />
          </div>
        </div>

        <p>
          Timeline dapat berubah sewaktu-waktu. Mohon mengikuti informasi terbaru melalui
          lini masa <a href='http://linktr.ee/bakriecenterfoundation' target='_blank' rel="noreferrer">media sosial Bakrie Center Foundation</a>
        </p>
      </section>

      <section className='text-center' style={{ marginTop: 30 }}>
        <h3>
          Silabus/KPI Kegiatan Magang Campus Leaders Program Batch 10
        </h3>
        <p>
          Pelajari terlebih dahulu silabus dan Key Performance Indicators (KPI) yang merupakan pedoman kegiatan berikut. Pastikan silabus/KPI kegiatan dapat dikonversi dengan mata kuliah yang diambil. Mahasiswa perlu berkonsultasi dengan dosen, program studi, fakultas, maupun pihak universitas terlebih dahulu.
          Silabus/KPI CLP 10: <a href='http://bit.ly/SilabusKPICLP10BCF' target='_blank' rel="noreferrer">bit.ly/SilabusKPICLP10BCF</a>
        </p>
      </section>

      <section style={{ marginTop: 30 }}>
        <div className="text-center" style={{ marginBottom: 30 }}>
          <h3>Frequently Asked Question</h3>
          <h3>Campus Leaders Program batch 10</h3>
        </div>

        <div class="accordion" id="accordionExample">
          <div class="accordion-item">
            <h2 class="accordion-header">
              <button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                Kabarnya, pelaksanaan MSIB saat  ini sedang ditangguhkan. Apakah ini berdampak pada pendaftaran Campus Leader Program batch 10?
              </button>
            </h2>
            <div id="collapseOne" class="accordion-collapse collapse show" data-bs-parent="#accordionExample">
              <div class="accordion-body">
                Halo Sahabat BCF! Saat ini memang program MSIB dari Kemendikti Saintek masih belum berjalan kembali. Namun, jangan khawatir kamu tetap bisa mengikuti <b>Campus Leaders Program batch 10</b> dengan skema magang mandiri.
              </div>
            </div>
          </div>
          <div class="accordion-item">
            <h2 class="accordion-header">
              <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                Seperti apa skema magang mandiri dalam Campus Leaders Program batch 10?
              </button>
            </h2>
            <div id="collapseTwo" class="accordion-collapse collapse" data-bs-parent="#accordionExample">
              <div class="accordion-body">
                Seluruh pelaksanaan magang CLP batch 10 tetap akan berjalan sesuai silabus/KPI per divisi. SDGs Heroes akan tetap ditempatkan di lembaga-lembaga sosial mitra BCF yang tersebar di <b>8 provinsi</b>. Namun, dalam skema magang mandiri, SDGs Hero <b>tidak mendapatkan uang saku</b> maupun <b>dana transportasi</b> selama magang, seperti halnya yang selama ini diberikan oleh Kemendikti Saintek dalam MSIB.. Maka, kami mengutamakan SDGs Hero dapat <b>memilih lokasi magang</b> yang <b>sesuai dengan asal domisili tempat tinggal maupun domisili universitas</b> untuk memudahkan proses magang secara luring (offline).
              </div>
            </div>
          </div>
          <div class="accordion-item">
            <h2 class="accordion-header">
              <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                Pelaksanaannya di 8 provinsi, dimana saja?
              </button>
            </h2>
            <div id="collapseThree" class="accordion-collapse collapse" data-bs-parent="#accordionExample">
              <div class="accordion-body">
                Yuk, disimak lokasi pelaksanaan Campus Leader Program batch 10 beserta para mitra pelaksana!
                <img className='img-fluid rounded' src='https://firebasestorage.googleapis.com/v0/b/bcf-clp-app.appspot.com/o/landing-page-clp-10%2FPemberdayaan%20Ekonomi%20-%20Campus%20Leaders%20Program.webp?alt=media&token=9ba02c0f-ba3f-4e9b-a53f-42f867317198' alt='lokasi pelaksanaan Campus Leader Program batch 10' />
              </div>
            </div>
          </div>

          <div class="accordion-item">
            <h2 class="accordion-header">
              <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
                Apakah seluruh mahasiswa bisa ikut mendaftar CLP batch 10?
              </button>
            </h2>
            <div id="collapseFour" class="accordion-collapse collapse" data-bs-parent="#accordionExample">
              <div class="accordion-body">
                Sure! Program ini terbuka untuk seluruh mahasiswa di seluruh Indonesia, terutama untuk kamu yang <b>berdomisili di 8 provinsi</b> pelaksanaan CLP batch 10.
              </div>
            </div>
          </div>

          <div class="accordion-item">
            <h2 class="accordion-header">
              <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFive" aria-expanded="false" aria-controls="collapseFive">
                Di CLP batch 10, SDGs Hero bisa magang pada divisi apa saja?
              </button>
            </h2>
            <div id="collapseFive" class="accordion-collapse collapse" data-bs-parent="#accordionExample">
              <div class="accordion-body">
                Simak baik-baik, ya. Berikut ini pilihan divisi baik di penempatan wilayah Jakarta dan 8 wilayah lainnya.
                <ul>
                  <li>
                    Divisi untuk magang di penempatan Jakarta (Ruang lingkup magang Percepatan Pencapaian SDGs di Indonesia sebelum tahun 2030 melalui 3 cluster yaitu Kesehatan, Pendidikan, dan Lingkungan)
                    <ul>
                      <li>Program Development</li>
                      <li>Program Development : Youth Leadership</li>
                      <li>Program Development: Leadership & Sustainability</li>
                      <li>Program Development: Sustainable Development Initiatives</li>
                      <li>IT Development</li>
                      <li>Partnership Development</li>
                      <li>Media & Strategic Communications</li>
                      <li>Research & Advocation</li>
                      <li>Financial Development: Finance & Sustainable Fundraising</li>
                    </ul>
                  </li>
                  <li>
                    Divisi untuk magang di wilayah penempatan provinsi lainnya (Ruang lingkup magang Pemberdayaan Ekonomi Masyarakat dalam Ekosistem Rentan TBC)
                    <ul>
                      <li>Program Development</li>
                      <li>Marketing Development</li>
                    </ul>
                  </li>
                </ul>
              </div>
            </div>
          </div>

          <div class="accordion-item">
            <h2 class="accordion-header">
              <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseSix" aria-expanded="false" aria-controls="collapseSix">
                Untuk setiap pilihan divisi, bisa diliat dimana ya silabus/KPInya?
              </button>
            </h2>
            <div id="collapseSix" class="accordion-collapse collapse" data-bs-parent="#accordionExample">
              <div class="accordion-body">
                Silahkan klik link berikut <a href='http://bit.ly/SilabusKPICLP10BCF' target='_blank' rel="noreferrer">bit.ly/SilabusKPICLP10BCF</a> untuk mempelajari silabus dan capaian KPI dari setiap divisi.
              </div>
            </div>
          </div>

          <div class="accordion-item">
            <h2 class="accordion-header">
              <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseSeven" aria-expanded="false" aria-controls="collapseSeven">
                Adakah syarat administrasi yang harus disiapkan sebelum mengisi form pendaftaran secara online?
              </button>
            </h2>
            <div id="collapseSeven" class="accordion-collapse collapse" data-bs-parent="#accordionExample">
              <div class="accordion-body">
                Siapkan berkas-berkas berikut ini untuk memudahkan kamu mendaftar dalam <a href='https://clp.bcf.or.id/program' target='_blank' rel="noreferrer">clp.bcf.or.id</a> :
                <ul>
                  <li>Curicullum Vitae (CV)</li>
                  <li>Transkrip Nilai</li>
                  <li>Foto Diri</li>
                  <li>Surat Rekomendasi dari Program Studi (ditandatangi oleh Kaprodi)</li>
                </ul>
              </div>
            </div>
          </div>

          <div class="accordion-item">
            <h2 class="accordion-header">
              <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseNine" aria-expanded="false" aria-controls="collapseNine">
                Terus, kapan nih kita bisa mulai magang di Campus Leader Program batch 10?
              </button>
            </h2>
            <div id="collapseNine" class="accordion-collapse collapse" data-bs-parent="#accordionExample">
              <div class="accordion-body">
                Magang mandiri Campus Leaders Program batch 10 akan dimulai pada <b>27 Februari 2025 hingga 10 Juli 2025</b>. Pendaftaran melalui <a href='https://clp.bcf.or.id/program' target='_blank' rel="noreferrer">clp.bcf.or.id</a> akan ditutup pada <b>14 Februari 2025</b>.
              </div>
            </div>
          </div>

          <div class="accordion-item">
            <h2 class="accordion-header">
              <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTen" aria-expanded="false" aria-controls="collapseNine">
                Wah, oke deh min. Semakin mantap nih untuk ikutan Campus Leaders Program batch 10. Tapi, penasaran selama magang manfaat apa aja sih yang bisa SDGs Hero dapatkan?
              </button>
            </h2>
            <div id="collapseTen" class="accordion-collapse collapse" data-bs-parent="#accordionExample">
              <div class="accordion-body">
                Selain bisa <b>konversi mata kuliah sebanyak 20-24 SKS</b>, di Campus Leaders Program batch 10 kamu juga bisa dapetin:
                <ul>
                  <li><b>Sertifikat Keaktifan Pemuda</b> dalam Mencapai SDGs 2030 secara resmi <b>dari Bappenas</b></li>
                  <li><b>Mendapatkan pelatihan secara berkala</b> dari para profesional dan praktisi <b>senilai 10 juta rupiah.</b></li>
                  <li><b>Terhubung dengan jejaring mitra hexa helix</b> yang ada dalam ekosistem kemitraan BCF</li>
                  <li><b>Berkesempatan menjadi peserta & pemapar</b> bersama mentor dan dosen dalam <b>Konferensi Nasional</b></li>
                  <li><b>Berkesempatan menjadi komite</b> dalam pelaksanaan kegiatan seminar yang diadakan Bakrie Center Foundation baik skala nasional maupun internasional</li>
                  <li>Mendapatkan <b>dana dukungan untuk implementasi proyek mahasiswa</b>, dan</li>
                  <li>Berpeluang untuk melakukan riset/skripsi/tugas akhir berdasarkan temuan dari kegiatan yang dilakukan selama magang</li>
                </ul>
              </div>
            </div>
          </div>

        </div>
      </section>

      <section style={{ marginTop: 30 }}>
        <p>
          Yuk persiapkan diri kamu untuk mendaftar Campus Leaders Program Batch 10 dengan menyiapkan berkas-berkas berikut ini untuk memudahkan kamu mendaftar yah:
        </p>
        <ul>
          <li>Curriculum Vitae (CV)</li>
          <li>Transkrip Nilai</li>
          <li>Foto Diri</li>
          <li>Portofolio</li>
          <li>
            Surat Pernyataan Kelayakan (ditandatangi oleh Ketua Program Studi) yang dapat diunduh melalui tautan berikut{" "}
            <a href='https://bit.ly/Template-SuratPernyataanKelayakan' target='_blank' rel="noreferrer">https://bit.ly/Template-SuratPernyataanKelayakan</a>
          </li>
          <li>Sertifikat prestasi/pendukung lainnya</li>
        </ul>
      </section>

      <section className='text-center' style={{ marginTop: 30 }}>
        <Link to="/program" className='btn btn-primary btn-lg'>Daftar</Link>
      </section>

      <section style={{ marginTop: 40 }}>
        <p>Lebih lengkap terkait dengan Campus Leaders Program Batch 10 dapat mengakses tayangan Konsolidasi Kampus Campus Leaders Program Batch 10 di bawah ini</p>
        <div class="d-flex justify-content-center">
          <iframe className='rounded' width={600} height={400} src="https://www.youtube.com/embed/9XnuvzhnwXY?si=UtPQ-ALstgLD8N-H" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
        </div>
      </section>

      <section className='text-center' style={{ marginTop: 30 }}>
        <h4>NARAHUBUNG</h4>
        <p>
          Pertanyaan lebih lanjut dapat menghubungi Tim Bakrie Center Foundation melalui kontak berikut.
        </p>
        <p>
          Email: <a href='mailto:rany.siyratu@bcf.or.id'>rany.siyratu@bcf.or.id</a>
        </p>
        <p>
          Media Sosial BCF: <a href='http://linktr.ee/bakriecenterfoundation' target='_blank' rel="noreferrer">linktr.ee/bakriecenterfoundation</a>
        </p>
      </section>
    </>
  )
}

export default Home