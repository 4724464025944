import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import api from '../utils/api';
import { useForm } from 'react-hook-form';

const Register = () => {
  const navigate = useNavigate();
  const { register, handleSubmit, formState: { errors, touchedFields } } = useForm();

  const [isLoading, setIsLoading] = useState(false);

  const onHandleRegister = async (data) => {
    setIsLoading(true);
    const { status, message } = await api.register(data)
    setIsLoading(false);
    if (status !== 'success') {
      alert(message);
    } else {
      alert(message);
      navigate('/app/login');
    }
  }

  const getInputClassName = (fieldName) => {
    if (!touchedFields[fieldName]) return 'form-control'; // Initial render
    return `form-control ${errors[fieldName] ? 'is-invalid' : 'is-valid'}`;
  };

  return (
    <div class="col-md-10 mx-auto col-lg-5">
      <form onSubmit={handleSubmit(onHandleRegister)} class="p-4 p-md-5 border rounded-3 bg-body-tertiary needs-validation" noValidate>
        <div className="row g-3">
          <h4>Buat akun baru</h4>
          <hr />

          <div className="col-12">
            <div class="form-floating">
              <input {...register("fullname", { required: 'Nama lengkap wajib diisi!' })} className={getInputClassName('fullname')} type="text" id="floatingFullName" />
              <label for="floatingFullName">Nama Lengkap</label>
              <div className="invalid-feedback">{errors.fullname?.message}</div>
              <div className="valid-feedback">Looks good!</div>
            </div>
          </div>

          <div className="col-12">
            <div class="form-floating">
              <input {...register("email", {
                required: 'Email tidak boleh kosong',
                pattern: {
                  value: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
                  message: 'Format email tidak valid',
                },
              })} className={getInputClassName('email')} type="email" id="floatingEmail" placeholder="name@example.com" />
              <label for="floatingEmail">Email</label>
              <div className="invalid-feedback">{errors.email?.message}</div>
              <div className="valid-feedback">Looks good!</div>
            </div>
          </div>

          <div className="col-12">
            <div class="form-floating">
              <input {...register("password", {
                required: 'Password wajib diisi!',
                minLength: {
                  value: 8,
                  message: 'Minimal 8 karakter',
                },
                pattern: {
                  value: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).+$/,
                  message: 'Mengandung kombinasi huruf kecil, huruf besar, dan angka',
                }
              })} className={getInputClassName('password')} type="password" id="floatingPassword" placeholder="xxx" />
              <label for="floatingPassword">Password</label>
              <div className="invalid-feedback">{errors.password?.message}</div>
              <div className="valid-feedback">Looks good!</div>
            </div>
          </div>

          <button class="w-100 btn btn-lg btn-primary" type="submit">
            {isLoading ? (
              <>
                <span class="spinner-border spinner-border-sm" aria-hidden="true"></span>
                <span class="visually-hidden" role="status">Loading...</span>
              </>
            ) : (
              <span>Daftar</span>
            )}
          </button>

          <hr />
          <small class="text-body-secondary">Sudah punya akun? <Link to="/app/login">masuk</Link></small>
        </div>
      </form >
    </div >
  )
}

export default Register;
