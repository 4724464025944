import React from "react";
import { Line } from "react-chartjs-2";
import { Chart, CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend } from "chart.js";

// Register required Chart.js components
Chart.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend);

const LineChart = ({ data }) => {
  // Extract labels (dates) and values (counts) from data
  const labels = data.map((item) => item.created_date);
  const values = data.map((item) => item.total_users);

  const chartData = {
    labels,
    datasets: [
      {
        label: "New Users Per Day",
        data: values,
        borderColor: "rgba(75,192,192,1)",
        backgroundColor: "rgba(75,192,192,0.2)",
        borderWidth: 2,
        tension: 0.4, // Smooth curve
      },
    ],
  };

  const options = {
    responsive: true,
    plugins: {
      legend: { display: true },
      title: { display: true, text: "User Registrations Per Day" },
    },
    scales: {
      x: { title: { display: true, text: "Date" } },
      y: { title: { display: true, text: "Number of Users" }, beginAtZero: true },
    },
  };

  return <Line data={chartData} options={options} />;
};

export default LineChart;
