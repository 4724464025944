import React, { useState, useEffect } from 'react';
import api from '../../../utils/api';
import { useNavigate } from 'react-router-dom';

const ClassificationsAdd = ({ authUser }) => {
  const { batchId } = authUser?.currentlyProgram.batch || {};
  const navigate = useNavigate();

  const [data, setData] = useState({
    divisiLembagaId: 0,
    classifications: ['', '', '', '', ''],
  });

  const [institutions, setInstitutions] = useState([]);
  const [divisions, setDivisions] = useState([])
  const [disabled, setDisabled] = useState(true);
  const [isLoading, setIsloading] = useState(false);

  useEffect(() => {
    const getInstitutions = async () => {
      const data = await api.adminGetInstitutions({ filterBy: 'mentor', batchId });
      setInstitutions(data);
    }

    getInstitutions()
  }, []);

  const onHandleDivisiByInstitutionId = async ({ id }) => {
    const data = await api.adminGetInstitutionsById({ id, batchId, filterBy: 'mentor' });
    setDivisions(data.divisions);
  }

  const onHandleClassifications = ({ value, key }) => {
    const newState = { ...data };
    newState.classifications[key] = value;
    setData(newState);
    if (newState.classifications[key] === '') {
      setDisabled(true);
    } else {
      setDisabled(false)
    }
  }

  const onHandlePostClassifications = async () => {
    setIsloading(true);
    console.log(data);
    setTimeout(async () => {
      const message = await api.adminAddClassifications({ payload: data });
      setIsloading(false);
      // alert(message);
      alert('KPI berhasil disimpan!');
      navigate('/mentor/classifications')
    }, 1000);
    setData({
      divisionInstitutionsId: 0,
      classifications: ['', '', '', '', ''],
    });
    setDivisions([]);
  }

  return (
    <div className='row'>
      <div className="col-12 mb-sm-3">
        <div className="shadow-sm">
          <div className="card-body p-3">
            <h1 className='h5'>KPI Hardskills</h1>
            <div className="row g-3">

              <div className="col-6">
                <label htmlFor="country" className="form-label">Lembaga</label>
                <select onChange={(e) => onHandleDivisiByInstitutionId({ id: e.target.value })} className="form-select" required>
                  <option defaultValue>Pilih Lembaga</option>
                  {institutions.map((institution, key) => (
                    <option key={key} value={institution.institutionsId}>{institution.institutionsName}</option>
                  ))}
                </select>
              </div>

              <div className="col-6">
                <label htmlFor="country" className="form-label">Divisi</label>
                <select disabled={!divisions?.length > 0} onChange={(e) => setData({ ...data, divisionInstitutionsId: e.target.value })} className="form-select" required>
                  <option defaultValue>Pilih Divisi</option>
                  {divisions?.map((division, key) => (
                    <option key={key} value={division.institutionsDivisionId}>{division.divisionName}</option>
                  ))}
                </select>
              </div>

              {data?.classifications?.map((classification, key) => (
                <div className="col-12">
                  <label className="form-label">KPI {key + 1}</label>
                  <input value={classification} onChange={(e) => onHandleClassifications({ value: e.target.value, key })} type="text" className="form-control" required />
                </div>
              ))}

              <div className="col-12">
                <div className="d-grid gap-2">
                  <button disabled={divisions?.length <= 0 || disabled || isLoading} onClick={() => onHandlePostClassifications()} className='btn btn-primary'>
                    {isLoading ?
                      <>
                        <span class="spinner-border spinner-border-sm" aria-hidden="true"></span>
                        <span role="status"> Loading...</span>
                      </>
                      :
                      <>
                        <span>Simpan</span>
                      </>
                    }
                  </button>
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ClassificationsAdd