import React, { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import api from '../../../utils/api';

const MentorsAdd = () => {
  const navigate = useNavigate();

  const { register, handleSubmit, setValue, watch } = useForm({
    defaultValues: {
      divisionInstitutionsId: [],
    }
  });

  const batchId = watch("batchId");

  const [isLoading, setIsloading] = useState(false);
  const [institutions, setInstitutions] = useState([]);
  const [divisionHasStudents, setDivisionHasStudents] = useState([]);
  const [mentoring, setMentoring] = useState([]);

  useEffect(() => {
    const getInstitutions = async () => {
      setIsloading(true);
      const data = await api.adminGetInstitutions({ batchId });
      setInstitutions(data);
      setDivisionHasStudents([]);
      setIsloading(false);
    }

    getInstitutions();
  }, [batchId]);

  const onHandleDivisiByInstitutionId = async ({ id, key }) => {
    const data = await api.adminGetInstitutionsById({ id, batchId });
    const newState = [...mentoring];
    newState[key].institutionId = id;
    newState[key].divisions = data.divisions;
    setMentoring(newState);
  }

  const onHandleDivisiId = ({ value, key }) => {
    const { divisionKey, id } = JSON.parse(value) || {};
    if (divisionKey === null || id === null) return;
    setValue(`divisionInstitutionsId[${key}]`, parseInt(id), { shouldValidate: true })

    const newdivisionHasStudents = [...divisionHasStudents];
    newdivisionHasStudents[key] = mentoring[key].divisions[divisionKey].students;
    setDivisionHasStudents(newdivisionHasStudents);
  }

  const onHandleSubmit = async (data) => {
    setIsloading(true);
    const { status, message } = await api.adminAddMentors({ payload: data });
    if (status !== 'success') {
      alert(message);
    } else {
      alert(message);
      navigate('/admin/mentors');
    }
    setMentoring([{}]);
    setIsloading(false);
  }

  return (
    <form onSubmit={handleSubmit(onHandleSubmit)} className="needs-validation" noValidate>
      <div className='row'>
        <div className="col-12 col-md-6 mb-3">
          <div className="card">
            <div className="card-body">
              <h1 className='h5'>Mentor</h1>
              <div className="row g-3">

                <div className="col-12">
                  <label htmlFor="nama" className="form-label">Nama</label>
                  <input
                    {...register("fullName", { required: 'Nama Lengkap wajib diisi!' })}
                    type="text" className="form-control" />
                </div>

                <div className="col-12">
                  <label htmlFor="nama" className="form-label">Email</label>
                  <input
                    {...register("email", { required: 'Email wajib diisi!' })}
                    type="text" className="form-control" />
                </div>

                <div className="col-12">
                  <label htmlFor="jabatan" className="form-label">Jabatan</label>
                  <input
                    {...register("position", { required: 'Jabatan wajib diisi!' })}
                    type="text" className="form-control" />
                </div>

                <div className="col-12">
                  <label htmlFor="keahlian" className="form-label">Keahlian</label>
                  <input
                    {...register("skill", { required: 'Keahlian wajib diisi!' })}
                    type="text" className="form-control" />
                </div>

                <div className="col-12">
                  <div className="d-grid gap-2">
                    <button type="submit" disabled={mentoring.length < 1 || isLoading} className='btn btn-primary'>
                      {isLoading ?
                        <>
                          <span class="spinner-border spinner-border-sm" aria-hidden="true"></span>
                          <span role="status"> Loading...</span>
                        </>
                        :
                        <>
                          <span>Simpan</span>
                        </>
                      }
                    </button>
                  </div>
                </div>

              </div>
            </div>
          </div>
        </div>

        <div className="col-md-6">
          <div className="card">
            <div className="card-body">
              <div className="d-flex justify-content-between mb-3">
                <h1 className='h5'>Mentoring</h1>
                <div className="col-6">
                  <select
                    {...register("batchId", { required: 'Batch? wajib diisi!' })}
                    className="form-select"
                  >
                    <option value={1}>BATCH: 8</option>
                    <option value={2}>BATCH: 9</option>
                    <option value={3}>BATCH: 10</option>
                  </select>
                </div>
              </div>

              {mentoring?.map((e, key) => (
                <div className="row g-3">

                  <div className="col-6">
                    <label htmlFor="country" className="form-label">Lembaga</label>
                    <select disabled={isLoading} onChange={(e) => onHandleDivisiByInstitutionId({ id: e.target.value, key })} className="form-select" required>
                      <option defaultValue>Pilih Lembaga</option>
                      {institutions.map((value, key) => (
                        <option key={key} value={value.institutionsId}>{value.institutionsName}</option>
                      ))}
                    </select>
                  </div>

                  <div className="col-6">
                    <label htmlFor="country" className="form-label">Divisi</label>
                    <select disabled={!mentoring[key]?.divisions?.length > 0} onChange={(e) => onHandleDivisiId({ value: e.target.value, key })} className="form-select" required>
                      <option value={JSON.stringify({ divisionKey: null, id: null })} defaultValue>Pilih Divisi</option>
                      {mentoring[key]?.divisions?.map((value, key) => (
                        <option key={key} value={JSON.stringify({ divisionKey: key, id: value.institutionsDivisionId })}>
                          {value.divisionName}
                          {' | Batch: '}
                          {value?.batch?.batch}
                        </option>
                      ))}
                    </select>
                  </div>

                  <div key={key} className="col-12">
                    <div className="col-12 table-responsive">
                      <table className='table table-hover'>
                        <thead>
                          <tr>
                            <th scope="col">#</th>
                            <th scope="col">Nama</th>
                            <th scope="col">Universitas</th>
                          </tr>
                        </thead>
                        <tbody>
                          {divisionHasStudents[key]?.map((value, key) => (
                            <tr key={key} onClick={() => navigate(`/admin/users/${value.CLPUsersId}`)} style={{ cursor: 'pointer' }}>
                              <th scope="row">{key + 1}</th>
                              <td>{`${value.firstName} ${value.lastName}`}</td>
                              <td>{value.universitiesName}</td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </div>

                </div>
              ))}

              <div className="d-grid gap-2 mt-3">
                <button type="button" disabled={mentoring.length > 4 || isLoading} onClick={() => setMentoring([...mentoring, {
                  institutionId: 388,
                  divisions: []
                }])} className='btn btn-primary'>
                  {isLoading ?
                    <>
                      <span class="spinner-border spinner-border-sm" aria-hidden="true"></span>
                      <span role="status"> Loading...</span>
                    </>
                    :
                    <>
                      <span>Tambah</span>
                    </>
                  }
                </button>
              </div>

            </div>
          </div>
        </div>
      </div >
    </form>
  )
}

export default MentorsAdd;
