import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useLocation, useNavigate } from 'react-router-dom';
import api from '../../../utils/api';

const Institutions = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const { data, regenciesId } = location?.state || {};

  const [isLoading, setIsLoading] = useState(true);
  const [isLoadingDivision, setIsLoadingDivision] = useState(true);
  const [institutions, setInstitutions] = useState([]);
  const [division, setDivisions] = useState({});

  // Initialize with defaultValues from data
  const { register, handleSubmit, setValue } = useForm({
    defaultValues: data || {},
  });

  // If data comes late, update the form values
  useEffect(() => {
    if (data) {
      Object.keys(data).forEach((key) => {
        setValue(key, data[key]);
      });
    }
  }, [data, setValue]);

  useEffect(() => {
    const onHandlerGetInstitutions = async () => {
      const data = await api.getRegistrationsInstitutions({
        batchId: 3,
        regenciesId,
      });
      setInstitutions(data);

      setValue('divisionInstitutionsId', data.length > 0 ? parseInt(data[0]?.divisionInstitutionsId) : null);

      if (data.length > 0) {
        onHandleGetInstitutionsDivision({ id: data.length > 0 ? data[0]?.divisionInstitutionsId : 0 });
      }

      setIsLoading(false);
    }

    onHandlerGetInstitutions();
  }, []);

  const onHandleGetInstitutionsDivision = async ({ id }) => {
    const data = await api.getRegistrationsInstitutionsDetail({
      divisionInstitutionsId: id,
      batchId: 3
    });
    setValue('divisionInstitutionsId', id);
    setDivisions(data);
    setIsLoadingDivision(false);
  }

  const onSubmit = (data) => {
    navigate('/program/registrations/others', { state: { data } })
  }

  if (isLoading) {
    return (
      <div class="d-flex justify-content-center">
        <div class="spinner-border" role="status">
          <span class="visually-hidden">Loading...</span>
        </div>
      </div>
    )
  }

  if (!institutions.length > 0) {
    return (
      <div class="alert alert-primary" role="alert">
        Domisili kamu tidak terjangkau.
      </div>
    )
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className='row g-3 m-auto' style={{ maxWidth: 850, padding: '1rem' }}>

        <div className="col-5">

          <div className="row g-3">
            {institutions.map((value, key) => (
              <div className="card">
                <div class="card-body">
                  <h6 class="card-title">{value?.divisionName}</h6>
                  <p>{value?.name}</p>
                  <button type="button" onClick={() => onHandleGetInstitutionsDivision({ id: value.divisionInstitutionsId })} class="btn btn-primary btn-sm">Detil</button>
                </div>
              </div>
            ))}
          </div>

        </div>

        <div className="col-7">
          <div className="sticky-md-top" style={{ top: 20, zIndex: 99 }}>
            {isLoadingDivision ?
              (
                <div class="d-flex justify-content-center">
                  <div class="spinner-border" role="status">
                    <span class="visually-hidden">Loading...</span>
                  </div>
                </div>
              )
              :
              (
                <>
                  <div className="card mb-3">
                    <div className="card-body">
                      <h5>{division?.division?.divisionName}</h5>
                      <p>{division?.institutionsName} - {division?.institutionsLocation}</p>
                      <hr />
                      <button type="submit" class="btn btn-primary">Daftar</button>
                      <hr />
                      <h6>Kompetensi yang akan dikembangkan</h6>
                      <ul>
                        {division?.classifications?.map((value) => (
                          <li>{value?.classification}</li>
                        ))}
                      </ul>

                      <h6>Rincian Kegiatan</h6>
                      {division?.requirementsDivision?.map((value, key) => (
                        <>
                          <p>Jurusan: {value.major}</p>
                          <p>Jenjang: {value.educational_level}</p>
                          <p>Minimal Semester: {value.min_semester}</p>
                          <div dangerouslySetInnerHTML={{ __html: value?.criteria_hardskill }} />
                          <div dangerouslySetInnerHTML={{ __html: value?.criteria_softskill }} />
                          <div dangerouslySetInnerHTML={{ __html: value?.criteria_others?.replace(/<a href="(bit\.ly\/[^"]+)"/g, '<a href="https://$1" target="_blank" rel="noopener noreferrer"') }} />
                        </>
                      ))}
                    </div>
                  </div>

                  <h5>Rekomendasi Divisi</h5>
                  {division?.recomendationDivision?.map((value) => (
                    <div className="card mb-2">
                      <div class="card-body">
                        <h6 class="card-title">{value?.divisionName}</h6>
                        <p>{value?.name}</p>
                        <button type="button" onClick={() => onHandleGetInstitutionsDivision({ id: value.divisionInstitutionsId })} class="btn btn-primary btn-sm">Detil</button>
                      </div>
                    </div>
                  ))}
                </>
              )
            }
          </div>
        </div>

      </div>
    </form >
  )
}

export default Institutions