import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form';
import { useLocation } from 'react-router-dom';
import api from '../../../utils/api';

const Others = ({ setRefreshPreloadProcess }) => {
  const location = useLocation();

  const { data } = location.state || {};

  const [isLoading, setIsLoading] = useState(false);

  // Initialize with defaultValues from data
  const { register, handleSubmit, setValue, formState: { errors, touchedFields } } = useForm({
    defaultValues: data || {},
  });

  // If data comes late, update the form values
  useEffect(() => {
    if (data) {
      Object.keys(data).forEach((key) => {
        setValue(key, data[key]);
      });
    }
  }, [data, setValue]);

  const getInputClassName = (fieldName) => {
    if (!touchedFields[fieldName]) return 'form-control'; // Initial render
    return `form-control ${errors[fieldName] ? 'is-invalid' : 'is-valid'}`;
  };

  const onHandleSubmit = async (data) => {
    setIsLoading(true);
    const { status, message } = await api.addRegistrations({ payload: data });
    setIsLoading(false);
    if (status !== 'success') {
      alert(message);
    } else {
      alert(message);
      await api.refreshToken();
      setRefreshPreloadProcess(true);
    }
  }

  return (
    <form onSubmit={handleSubmit(onHandleSubmit)} className="needs-validation" noValidate>
      <div className="row g-3 m-auto" style={{ maxWidth: 850, padding: '1rem' }}>

        <div className="card">
          <div className="card-body">
            <h4 className="mb-3">Berkas</h4>

            <div className="row g-3">

              <div className="col-12">
                <label htmlFor="address" className="form-label">Curriculum Vitae * (URL Google Drive)</label>
                <input
                  {...register("curriculumVitae", { required: 'Curriculum Vitae wajib diisi!' })}
                  className={getInputClassName('curriculumVitae')}
                  type="text"
                  placeholder='https://drive.google.com/file/d/'
                />
                <div className="invalid-feedback">{errors.curriculumVitae?.message}</div>
                <div className="valid-feedback">Looks good!</div>
              </div>

              <div className="col-12">
                <label htmlFor="address" className="form-label">Transcript * (URL Google Drive)</label>
                <input
                  {...register("academicTranscript", { required: 'Transcript wajib diisi!' })}
                  className={getInputClassName('academicTranscript')}
                  type="text"
                  placeholder='https://drive.google.com/file/d/'
                />
                <div className="invalid-feedback">{errors.academicTranscript?.message}</div>
                <div className="valid-feedback">Looks good!</div>
              </div>

              <div className="col-12">
                <label htmlFor="address" className="form-label">Portofolio (URL Google Drive)</label>
                <input
                  {...register("portfolio")}
                  className={getInputClassName('portfolio')}
                  type="text"
                  placeholder='https://drive.google.com/file/d/'
                />
              </div>

              <div className="col-12">
                <label htmlFor="address" className="form-label">Surat Keterangan Kelayakan dari Universitas * (URL Google Drive)</label>
                <input
                  {...register("eligibilityFromUniversity")}
                  className={getInputClassName('eligibilityFromUniversity')}
                  type="text"
                  placeholder='https://drive.google.com/file/d/'
                />
              </div>

              <div className="col-12">
                <label htmlFor="address" className="form-label">Sertifikat Organisasi (URL Google Drive)</label>
                <input
                  {...register("organizationCertificateOne")}
                  className={getInputClassName('organizationCertificateOne')}
                  type="text"
                  placeholder='https://drive.google.com/file/d/'
                />
              </div>

            </div>

          </div>
        </div>

        <div className="card">
          <div className="card-body">
            <h4 className="mb-3">Others</h4>

            <div className="row g-3">

              <div className="col-12">
                <label htmlFor="address" className="form-label">Bukti Follow Akun Media Sosial Bakrie Center Foundation * (URL Google Drive)</label>
                <ul>
                  <li>Masukkan LINK DRIVE yang berisi screenshot bahwa aku kamu telam follow akun media sosial yang tertera di <a href='https://linktr.ee/CampusLeadersProgramBCF' target="_blank" rel="noreferrer">linktr.ee/CampusLeadersProgramBCF.</a> Pastikan link drive dengan akses yang terbuka (akses VIEW) agar tim BCF dapat mereview dengan mudah</li>
                  <li>Masing-masing media sosial harus ada screenshootnya ya, kawan!</li>
                </ul>
                <input
                  {...register("evidenceFollowBcf", { required: 'Bukti Follow Akun Media Sosial Bakrie Center Foundation wajib diisi!' })}
                  className={getInputClassName('evidenceFollowBcf')}
                  type="text"
                  placeholder='https://drive.google.com/file/d/'
                />
                <div className="invalid-feedback">{errors.evidenceFollowBcf?.message}</div>
                <div className="valid-feedback">Looks good!</div>
              </div>

              <div className="col-12">
                <label htmlFor="address" className="form-label">Bukti Follow Seluruh Akun Media Sosial Lembaga Mitra BCF dan Lembaga Provinsi Penempatan Magang * (URL Google Drive)</label>
                <ul>
                  <li>Masukkan LINK DRIVE yang berisi screenshot bahwa aku kamu telam follow akun media sosial yang tertera di <a href='https://linktr.ee/CampusLeadersProgramBCF' target="_blank" rel="noreferrer">linktr.ee/CampusLeadersProgramBCF.</a> Pastikan link drive dengan akses yang terbuka (akses VIEW) agar tim BCF dapat mereview dengan mudah</li>
                  <li>Masing-masing media sosial harus ada screenshootnya ya, kawan!</li>
                </ul>
                <input
                  {...register("evidenceFollowFellow", { required: 'Bukti Follow Seluruh Akun Media Sosial Lembaga Mitra BCF dan Lembaga Provinsi Penempatan Magang wajib diisi!' })}
                  className={getInputClassName('evidenceFollowFellow')}
                  type="text"
                  placeholder='https://drive.google.com/file/d/'
                />
                <div className="invalid-feedback">{errors.evidenceFollowFellow?.message}</div>
                <div className="valid-feedback">Looks good!</div>
              </div>

              <div className="col-12">
                <label htmlFor="address" className="form-label">Apa yang Kamu Pikirkan Ketika Mendengar Nama Bakrie Center Foundation? *</label>
                <ul>
                  <li>Boleh menyebutkan kata yang terpikirkan, atau deskripsi kesan yang terbersit ketika mendengar kata tersebut, tanpa mencari tahu terlebih dahulu di internet</li>
                </ul>
                <textarea
                  row={3}
                  {...register("wdytBcf", { required: 'Apa yang Kamu Pikirkan Ketika Mendengar Nama Bakrie Center Foundation? wajib diisi!' })}
                  className={getInputClassName('wdytBcf')}
                  type="text"
                />
                <div className="invalid-feedback">{errors.wdytBcf?.message}</div>
                <div className="valid-feedback">Looks good!</div>
              </div>

              <div className="col-12">
                <label htmlFor="address" className="form-label">Apa yang Kamu Pikirkan Ketika Mendengar Kata Tuberkulosis? *</label>
                <ul>
                  <li>Boleh menyebutkan kata yang terpikirkan, atau deskripsi kesan yang terbersit ketika mendengar kata tersebut, tanpa mencari tahu terlebih dahulu di internet</li>
                </ul>
                <textarea
                  row={3}
                  {...register("wdytTbc", { required: 'Apa yang Kamu Pikirkan Ketika Mendengar Kata Tuberkulosis? wajib diisi!' })}
                  className={getInputClassName('wdytTbc')}
                  type="text"
                />
                <div className="invalid-feedback">{errors.wdytTbc?.message}</div>
                <div className="valid-feedback">Looks good!</div>
              </div>

              <div className="col-12">
                <label htmlFor="address" className="form-label">Dari Mana Kamu Mengetahui Info Pendaftaran Campus Leaders Program di Bakrie Center Foundation? *</label>
                <select
                  {...register("infoJoiningClp", { required: 'Dari Mana Kamu Mengetahui Info Pendaftaran Campus Leaders Program di Bakrie Center Foundation? wajib diisi!' })}
                  className={getInputClassName('infoJoiningClp')}
                >
                  <option value="" defaultValue>Pilih</option>
                  <option value="Instagram">Instagram</option>
                  <option value="LinkedIn">LinkedIn</option>
                  <option value="Tiktok">Tiktok</option>
                  <option value="Pihak Universitas">Pihak Universitas</option>
                  <option value="Pihak Fakultas">Pihak Fakultas</option>
                  <option value="Pihak Program Studi">Pihak Program Studi</option>
                  <option value="Akun Media Sosial Universitas/Fakultas/Program Studi">Akun Media Sosial Universitas/Fakultas/Program Studi</option>
                  <option value="Dosen">Dosen</option>
                  <option value="Teman">Teman</option>
                  <option value="Keluarga">Keluarga</option>
                  <option value="Alumni Program-Program BCF">Alumni Program-Program BCF</option>
                  <option value="Platform Website Kampus Merdeka">Platform Website Kampus Merdeka</option>
                  <option value="Lainnya">Lainnya</option>
                </select>
                <div className="invalid-feedback">{errors.infoJoiningClp?.message}</div>
                <div className="valid-feedback">Looks good!</div>
              </div>

              <div className="col-12">
                <label htmlFor="address" className="form-label">Apakah Kamu Tertarik Mengetahui Lebih Lanjut mengenai Unit Bisnis di Bakrie Group? *</label>
                <select
                  {...register("interestMoreBakrieGroup", { required: 'Apakah Kamu Tertarik Mengetahui Lebih Lanjut mengenai Unit Bisnis di Bakrie Group? wajib diisi!' })}
                  className={getInputClassName('interestMoreBakrieGroup')}
                >
                  <option value="" defaultValue>Pilih</option>
                  <option value="YA" defaultValue>Ya</option>
                  <option value="TIDAK" defaultValue>Tidak</option>
                </select>
                <div className="invalid-feedback">{errors.interestMoreBakrieGroup?.message}</div>
                <div className="valid-feedback">Looks good!</div>
              </div>

              <div className="col-12">
                <label htmlFor="address" className="form-label">Apakah Kamu Bersedia Mendapatkan Newsletter Berisi Informasi Terkini dan Peluang Bergabung dalam Bakrie Group? *</label>
                <select
                  {...register("willingToReceiveInformationBakrieGroup", { required: 'Apakah Kamu Bersedia Mendapatkan Newsletter Berisi Informasi Terkini dan Peluang Bergabung dalam Bakrie Group? wajib diisi!' })}
                  className={getInputClassName('willingToReceiveInformationBakrieGroup')}
                >
                  <option value="" defaultValue>Pilih</option>
                  <option value="YA" defaultValue>Ya</option>
                  <option value="TIDAK" defaultValue>Tidak</option>
                </select>
                <div className="invalid-feedback">{errors.willingToReceiveInformationBakrieGroup?.message}</div>
                <div className="valid-feedback">Looks good!</div>
              </div>

              <div className="col-12">
                <label htmlFor="address" className="form-label">Tuliskan nama lengkap alumni Bakrie Center Foundation yang mengajak kamu mendaftar Campus Leaders Program Batch 10 (referral ini bersifat opsional)</label>
                <input
                  {...register("referral")}
                  className={getInputClassName('referral')}
                  type="text"
                />
                <div className="invalid-feedback">{errors.referral?.message}</div>
                <div className="valid-feedback">Looks good!</div>
              </div>

              <div className="col-12">
                <label htmlFor="address" className="form-label">Hal Apa yang Ingin Kamu Tanyakan/Informasi Apa yang Kamu Butuhkan dari Bakrie Center Foundation? *</label>
                <textarea rows={3}
                  {...register("askInformationBcf", { required: 'Hal Apa yang Ingin Kamu Tanyakan/Informasi Apa yang Kamu Butuhkan dari Bakrie Center Foundation? wajib diisi!' })}
                  className={getInputClassName('askInformationBcf')}
                  type="text"
                />
                <div className="invalid-feedback">{errors.askInformationBcf?.message}</div>
                <div className="valid-feedback">Looks good!</div>
              </div>

            </div>

          </div>
        </div>

        <button class="w-100 btn btn-primary" type="submit">
          {isLoading ? (
            <>
              <span class="spinner-border spinner-border-sm" aria-hidden="true"></span>
              <span class="visually-hidden" role="status">Loading...</span>
            </>
          ) : (
            <span>Daftar </span>
          )}
        </button>

      </div>
    </form>
  )
}

export default Others;
